import { useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useAppSelector } from '../../../../../app/hooks';
import { QuestionAnswerType } from '../../../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../../../types/InterviewQuestionTypes';
import useSetWatchQuestion from '../../../hooks/useSetWatchQuestion';
import { selectResetQuestion } from '../../../interviewSlice';
import { StyledClinicianNote as StyleTextQuestion } from '../../../interviewUtils';

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

export default function MinimizeTextQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  isFromGroup,
}: InterviewQuestionProps) {
  const theme = useTheme();
  const [bindDataFrom, setBindDatFrom] = useState<string>('');
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const [textFieldStyle, setTextFieldStyle] = useState<Record<string, any> | null>(null);

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.Text,
    required: !!question.isRequired,
  });

  const handleTextChange = (e: any) => {
    const value = e?.target?.value || '';
    setValue(field?.name!, value, { shouldValidate: true });
    setBindDatFrom(value);
  };

  useEffect(() => {
    if (!field?.value && question?.bindDataFrom) {
      setValue(field?.name!, question?.bindDataFrom);
      setBindDatFrom(question?.bindDataFrom);
    }
    if (question?.textPattern) {
      const textStylePattern = question?.textPattern?.split('|');
      if (textStylePattern.length > 1 && textStylePattern[0].trim() === QuestionAnswerType.Text) {
        setTextFieldStyle(JSON.parse(textStylePattern[1].trim()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isResetQuestion) {
      setValue(field?.name!, '');
      setBindDatFrom('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetQuestion]);

  return (
    <Box>
      <FormControl error={error} variant="standard" sx={{ width: 1 }}>
        <Box sx={{ mt: 2 }}>
          {isFromGroup ? (
            <Typography variant="body1" textAlign="center">
              {question.name?.includes('current') ? 'Current Episode' : 'Past Episode'}
            </Typography>
          ) : (
            <>
              {question.questionPrompt && (
                <StyledTypography
                  dangerouslySetInnerHTML={{
                    __html: question.questionPrompt,
                  }}
                ></StyledTypography>
              )}
              <Typography
                dangerouslySetInnerHTML={{
                  __html: question.questionText!.trim().length
                    ? question.questionText!
                    : question.name!,
                }}
              ></Typography>
            </>
          )}
          {!question?.textPattern || (question?.textPattern && textFieldStyle) ? (
            <StyleTextQuestion
              {...field!}
              disabled={!!question?.bindDataFrom}
              value={bindDataFrom || field?.value}
              onChange={handleTextChange}
              onBlur={() => handleOnBlur()}
              error={error}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              {...textFieldStyle}
            />
          ) : (
            <IMaskInput
              {...field!}
              disabled={!!question?.bindDataFrom}
              style={{
                font: 'inherit',
                letterSpacing: 'inherit',
                background: `${theme.xPalette.noteLightGrey}`,
                height: '60px',
                margin: '0',
                width: '100%',
                padding: '16.5px 14px',
                paddingRight: '0',
                border: 'none',
                borderRadius: '8px',
                outline: 'none',
              }}
              lazy={false}
              mask={question?.textPattern}
              value={bindDataFrom || field?.value}
              onAccept={(value: any, mask: any) =>
                field?.onChange({
                  target: { name: field?.name, value: mask.unmaskedValue ? value : '' },
                })
              }
              onBlur={() => handleOnBlur()}
              onChange={handleTextChange}
              overwrite
            />
          )}
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
      </FormControl>
    </Box>
  );
}
