import { ReactNode, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Breakpoint } from '@mui/material/styles';
import { ContentPopup } from '../../types/CommonType';
import { IntakePacketPopupBodyProp, IntakePopupType } from '../../types/PatientType';

interface PopupModalProps {
  isOpen: boolean;
  contentPopup: ContentPopup;
  maxWidth?: Breakpoint;
  hasEmailOrPhoneNumber?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
  onSuccess?: () => void;
  onSubmit?: (data?: IntakePopupType) => void;
  onClose?: () => void;
  bodyComponent?: React.FC<IntakePacketPopupBodyProp>;
}

export default function LocalPopupModal({
  isOpen,
  contentPopup,
  children,
  maxWidth,
  hasEmailOrPhoneNumber,
  fullWidth,
  onSuccess,
  onSubmit,
  onClose,
  bodyComponent: IntakePacketPopupBody,
}: PopupModalProps) {
  const [choseOption, setChoseOption] = useState<IntakePopupType | undefined>(
    hasEmailOrPhoneNumber
      ? IntakePopupType.EmailAndTextTheQuestionsToThePatient
      : IntakePopupType.PatientWillFillOutTheQuestions
  );
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setChoseOption(
      hasEmailOrPhoneNumber
        ? IntakePopupType.EmailAndTextTheQuestionsToThePatient
        : IntakePopupType.PatientWillFillOutTheQuestions
    );
  }, [hasEmailOrPhoneNumber]);

  const handleOk = () => {
    if (choseOption && onSubmit) {
      onSubmit(choseOption);
    } else if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={
        maxWidth || (contentPopup?.warningTitle && contentPopup?.warningContent ? 'sm' : 'md')
      }
      fullWidth={fullWidth}
      sx={{ zIndex: (theme) => theme.zIndex.drawer }}
    >
      <DialogTitle
        sx={{ pt: 6, textAlign: 'center', fontSize: (theme) => theme.typography.h1.fontSize }}
      >
        {contentPopup?.title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {contentPopup?.warningTitle && contentPopup?.warningContent && (
          <DialogContentText>
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.xPalette.warning, fontWeight: 900 })}
            >
              {contentPopup?.warningTitle}:
            </Typography>
            <Typography
              component="span"
              sx={(theme) => ({ color: theme.xPalette.warning, marginLeft: '5px' })}
            >
              {contentPopup?.warningContent}
            </Typography>
          </DialogContentText>
        )}
        <DialogContentText>{contentPopup?.description}</DialogContentText>
        {IntakePacketPopupBody && (
          <IntakePacketPopupBody
            setChoseOption={setChoseOption}
            hasEmailOrPhoneNumber={hasEmailOrPhoneNumber}
          />
        )}
        {children}
      </DialogContent>
      {(contentPopup?.btnOk || contentPopup?.btnClose) && (
        <DialogActions
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              height: '130px',
            },
          })}
        >
          <Link
            component="button"
            sx={(theme) => ({
              fontSize: '14px',
              [theme.breakpoints.down('sm')]: {
                height: '130px',
              },
              textTransform: 'none',
              width: '100%',
              padding: '12px 0px',
              [theme.breakpoints.up('sm')]: {
                width: '20%',
                minWidth: '120px',
              },
              [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                width: '95%',
                marginTop: '60px',
                textAlign: 'center',
              },
            })}
            onClick={handleClose}
          >
            {contentPopup?.btnClose}
          </Link>
          <Button
            variant="contained"
            sx={(theme) => ({
              backgroundColor: theme.palette.primary.main,
              textTransform: 'none',
              width: '100%',
              padding: '12px 0px',
              borderRadius: '6px',
              [theme.breakpoints.up('sm')]: {
                width: '20%',
                minWidth: '150px',
              },
              [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                width: '95%',
                marginBottom: '60px',
              },
            })}
            onClick={handleOk}
          >
            {contentPopup?.btnOk}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
