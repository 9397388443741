import { useState, useMemo } from 'react';
import { SequenceItemDto } from '../../../types/InterviewQuestionTypes';
import { getQuestionControlName } from '../interviewUtils';

const usePaginationQuestionByGroup = (
  currentSequenceItems: SequenceItemDto[]
): {
  currentGroup: any;
  totalPages: number;
  currentPage: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  isLastPage: boolean;
  currentControlNames: string[];
  resetCurrentPage: () => void;
} => {
  const [currentPage, setCurrentPage] = useState(0);

  const addToGroup = (pageIndex: number, questionGroup: any, groups: any, currentSequence: any) => {
    if (questionGroup) {
      const groupIndex = groups[pageIndex].findIndex(
        (item: SequenceItemDto[]) =>
          Array.isArray(item) && item[0]?.question?.group?.split('|')[0] === questionGroup
      );
      if (groupIndex >= 0) {
        groups[pageIndex][groupIndex].push(currentSequence);
      } else {
        groups[pageIndex].push([currentSequence]);
      }
    } else {
      groups[pageIndex].push(currentSequence);
    }
  };

  const { currentGroup, totalPages, currentControlNames } = useMemo(() => {
    const groups: any = [];
    let questionWithoutPageIndex: number | null = null;

    (currentSequenceItems || []).forEach((currentSequence: SequenceItemDto) => {
      const { question } = currentSequence;
      const [questionGroup, questionPage] = question?.group?.split('|') || [];

      const findPageIndex = () =>
        groups.findIndex(
          (item: SequenceItemDto[]) =>
            Array.isArray(item) && item[0]?.question?.group?.split('|')[1] === questionPage
        );

      if (questionPage) {
        const pageIndex = findPageIndex();
        if (pageIndex >= 0) {
          addToGroup(pageIndex, questionGroup, groups, currentSequence);
        } else {
          groups.push([currentSequence]);
        }
        questionWithoutPageIndex = null;
      } else {
        //None paging module will always have the page index = 0
        const primaryIndex = groups.length;
        const targetIndex = questionWithoutPageIndex ?? primaryIndex;

        if (!groups[targetIndex]) {
          groups.push([currentSequence]);
          questionWithoutPageIndex = primaryIndex;
        } else if (groups[targetIndex]) {
          addToGroup(targetIndex, questionGroup, groups, currentSequence);
        }
      }
    });

    const currentGroup: any[] = groups[currentPage];
    const totalPages = groups.length || 1;

    const currentControlNames = (currentGroup || []).flat().map((item: any) => {
      return getQuestionControlName(item.questionId);
    });

    return {
      currentGroup,
      totalPages,
      currentControlNames,
    };
  }, [currentPage, currentSequenceItems]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const resetCurrentPage = () => {
    setCurrentPage(0);
  };

  return {
    currentGroup,
    totalPages,
    currentPage,
    handleNextPage,
    handlePreviousPage,
    isLastPage: currentPage + 1 === totalPages,
    currentControlNames,
    resetCurrentPage,
  };
};

export default usePaginationQuestionByGroup;
