import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PersistConfig,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import globalReducer from '../common/slices/globalSlice';
import authReducer from '../features/auth/authSlice';
import interviewReducer from '../features/interview/interviewSlice';
import MonitorReducer from '../features/monitor/monitorSlide';
import patientReducer from '../features/patient/patientSlice';
import reminderReducer from '../features/site/patient-reminders/ReminderSlice';
import treatmentPlanReducer from '../features/treatment-plan/treatmentPlanSlice';
import syncBroadcastChannelMiddleware from './channels/syncBroadcastChannel';
import { rootApi } from './services';

// Persist store to local storage
const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  version: 1,
  blacklist: ['api', 'treatmentPlan', 'auth'], // Ignore those slides from local storage
};

// Persist store to session storage
const persistSessionConfig: PersistConfig<any> = {
  key: 'session',
  storage: sessionStorage,
  version: 1,
};

export const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  global: globalReducer,
  auth: persistReducer(persistSessionConfig, authReducer),
  patient: patientReducer,
  interview: interviewReducer,
  monitor: MonitorReducer,
  treatmentPlan: treatmentPlanReducer,
  reminder: reminderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(rootApi.middleware, syncBroadcastChannelMiddleware()),
});

export const persistor = persistStore(store);
export default store;

// For redux typescript
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
