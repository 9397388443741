import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ResultDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import {
  QueryCacheDuration,
  TagIds,
  TagTypes,
  buildInterviewUrl,
  buildMonitorUrl,
  rootApi,
} from '.';
import { AssessmentType, ImportType } from '../../common/utils/assessmentType';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { getFirstMonitorInPackets } from '../../features/interview/interviewUtils';
import {
  AssessmentListRequestType,
  AssessmentListResponseType,
  EditInterviewTypeInfoRequestDto,
  EditAssessmentTypeInfoResponseDto,
} from '../../types/AssessmentBuilderType';
import {
  AssessmentScheduleResponse,
  InterviewScheduleRequest,
} from '../../types/AssessmentSchedule';
import { CacheManagementRequest, CacheManagementResponse } from '../../types/CacheManagementType';
import { InterviewStatsResponse } from '../../types/DashboardRequest';
import { ExportInterviewResponse, ExportInterviewRequest } from '../../types/ExportAssessment';
import { GenerateDataFileResponse } from '../../types/GenerateDataFile';
import { ImportBuilder, ImportBuilderResponse } from '../../types/ImportBuilderType';
import {
  AssessmentReportByTokenRequest,
  DownloadReportRequest,
  DownloadReportResponse,
  InterviewReportHistoryRequest,
  InterviewReportHistoryResponse,
} from '../../types/InterviewReportRequest';
import {
  CheckExpireTokenRequest,
  CheckExpireTokenResponse,
  DeleteInterviewOrMonitorRequest,
  DeleteInterviewOrMonitorResponse,
  EditInterviewRequest,
  EditInterviewResponse,
  InterviewBuilderResponse,
  InterviewConductByTokenRequest,
  InterviewConductRequest,
  InterviewResponse,
  InterviewSettingsRequest,
  InterviewSettingsResponse,
  InterviewSubmissionByTokenRequest,
  InterviewSubmissionRequest,
  InterviewSubmissionResponse,
  RequestInterview,
} from '../../types/InterviewRequest';
import {
  DetailStructuredDataCodebookResponse,
  ExportStructuredResponse,
  ListStructuredDataCodebookResponse,
  CheckResultInProgressOfPacketResponse,
} from '../../types/InterviewTypes';
import { MasterUsageReportDownloadRequest } from '../../types/MasterUsageReport';
import {
  FirstLastInterviewsRequest,
  FirstLastInterviewsResponse,
  GenerateManagementReport,
  InterviewTypeRequest,
  InterviewTypesResponse,
  LatestInterviewResultsByPatientResponse,
  LatestInterviewsRequest,
  LatestInterviewsResponse,
  SendPatientReportToEHR,
  UnlockReportRequest,
  UnlockReportResponse,
} from '../../types/PatientRequest';
import { ChangeInterviewModeToClinicianResponse } from '../../types/PatientType';
import { AssessmentReport } from '../../types/ReportType';
import {
  RescoreAccountsResponse,
  RescoreJobInterviewRequest,
  RescoreJobInterviewResponse,
  RescorePatientsRequest,
  RescorePatientsResponse,
  StartRescoreInfoRequest,
} from '../../types/RescoreInterviewJob';
import {
  UsageReportDownloadResponse,
  UsageReportRequest,
  UsageReportResponse,
} from '../../types/UsageReport';
import { ClinicianNotesRequestDto, ClinicianNotesResponseDto, ClinicianNotesSubmissionRequestDto, ClinicianNotesSubmissionResponseDto } from '../../types/clinician-notes/ClinicianNotes';

const buildInterviewSubmissionInvalidatingTags: ResultDescription<
  string,
  InterviewSubmissionResponse,
  InterviewSubmissionRequest,
  FetchBaseQueryError
> = (result, error, arg) =>
  error
    ? []
    : result?.completed
    ? [
        {
          type: TagTypes.LatestInterviews,
          patientId: arg.patientId,
          id: result?.interviewTestId + '',
        },
        { type: TagTypes.LatestInterviews, patientId: arg.patientId },
        {
          type: TagTypes.LatestInterviewDetails,
          patientId: arg.patientId,
          id: result?.interviewTestId + '',
        },
      ]
    : [];

const buildRequestInterviewInvalidatingTags: ResultDescription<
  string,
  InterviewResponse,
  RequestInterview | InterviewConductRequest,
  FetchBaseQueryError
> = (result, error, arg) => {
  const requestInterview = arg as RequestInterview;
  if (!requestInterview) {
    return [];
  }
  const patientId = requestInterview.nViewPatientId;
  const invalidatingTags = [
    {
      type: TagTypes.LatestInterviews,
      patientId,
      id: arg.interviewTestId ? arg.interviewTestId + '' : TagIds.List,
    },
    {
      type: TagTypes.LatestInterviews,
      patientId,
    },
  ];

  // When requesting interviews, if there is any monitors in the measurement packet, then refetch monitors
  const measurementPackets = requestInterview.measurementPackets || [];
  if (measurementPackets?.length) {
    const hasMonitors = getFirstMonitorInPackets(measurementPackets) !== null;
    if (hasMonitors) {
      invalidatingTags.push({
        type: TagTypes.LatestMonitors,
        patientId,
      });
    }
  }

  return error
    ? []
    : !arg.interviewTestId && (result?.interviewTestId || result?.monitorTestId) // if creating new one (arg.interviewTestId is not passed)
    ? invalidatingTags
    : [];
};

const api = rootApi
  .enhanceEndpoints({
    addTagTypes: [
      TagTypes.LatestInterviews,
      TagTypes.LatestMonitors,
      TagTypes.LatestInterviewDetails,
      TagTypes.LatestMonitorDetails,
      TagTypes.InterviewTypes,
      TagTypes.LatestMonitors,
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLatestInterviews: builder.query<LatestInterviewsResponse, LatestInterviewsRequest>({
        query: ({ patientId, interviewTypeId, page, pageSize }) => ({
          url: buildInterviewUrl('/v1/interview/latest-interviews'),
          params: {
            patientId,
            interviewTypeId,
            page,
            pageSize,
          },
          method: 'GET',
        }),
        providesTags: (result, error, arg) =>
          result?.items
            ? [
                ...result.items.map(({ id: interviewResultId }) => ({
                  type: TagTypes.LatestInterviews,
                  patientId: arg.patientId,
                  id: interviewResultId + '',
                })),
                {
                  type: TagTypes.LatestInterviews,
                  patientId: arg.patientId,
                  id: TagIds.List,
                },
              ]
            : [],
      }),

      getLatestInterviewsByIds: builder.query<LatestInterviewsResponse, LatestInterviewsRequest>({
        query: ({ patientId, interviewResultIds }) => ({
          url: buildInterviewUrl('/v1/interview/latest-interviews'),
          params: {
            patientId,
            interviewResultIds: interviewResultIds?.join(',') || undefined,
          },
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.Short,
        providesTags: (result, error, arg) =>
          result?.items
            ? [
                ...result.items.map(({ id: interviewResultId }) => ({
                  type: TagTypes.LatestInterviewDetails,
                  patientId: arg.patientId,
                  id: interviewResultId + '',
                })),
                ...result.items.map(({ id: interviewResultId }) => ({
                  type: TagTypes.LatestInterviews,
                  patientId: arg.patientId,
                  id: interviewResultId + '',
                })),
              ]
            : [],
      }),
      queryUsageReport: builder.query<UsageReportResponse, UsageReportRequest>({
        query: (param) => ({
          url: buildInterviewUrl('/v1/interview-report/usage-report'),
          method: 'GET',
          params: {
            clinicAccountId: param.clinicAccountId,
            reportType: param.reportType,
            fromDate: param.fromDateString,
            toDate: param.toDateString,
            timezoneOffset: param.timezoneOffset || 0,
            page: param.page,
            pageSize: param.pageSize,
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      queryUsageReportForDownload: builder.query<UsageReportDownloadResponse, UsageReportRequest>({
        query: (param) => ({
          url: buildInterviewUrl('/v1/interview-report/download-usage-report-csv'),
          method: 'GET',
          params: {
            clinicAccountId: param.clinicAccountId,
            reportType: param.reportType,
            fromDate: param.fromDateString,
            toDate: param.toDateString,
            timezoneOffset: param.timezoneOffset || 0,
          },
          responseHandler: async (response: any) => {
            return {
              csvData: await response.text(),
            };
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getInterviewTypes: builder.query<InterviewTypesResponse, InterviewTypeRequest>({
        query: ({ patientId }) => ({
          url: buildInterviewUrl(`/v1/interview-builder/interview-types?patientId=${patientId}`),
        }),
        providesTags: (result, error, arg) =>
          error ? [] : [{ type: TagTypes.InterviewTypes, id: arg.patientId }],
      }),

      getInterviews: builder.query<InterviewBuilderResponse, void>({
        query: () => ({
          url: buildInterviewUrl(`/v1/interview-builder/interviews`),
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getInterviewStats: builder.query<InterviewStatsResponse, void>({
        query: () => ({
          url: buildInterviewUrl(`/v1/interview/stats/`),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      requestInterview: builder.mutation<
        InterviewResponse,
        RequestInterview | InterviewConductRequest
      >({
        query: (interviewInfo) => ({
          url: buildInterviewUrl('/v1/interview/request-interview'),
          method: 'POST',
          body: interviewInfo,
        }),
        invalidatesTags: buildRequestInterviewInvalidatingTags,
      }),

      requestInterviewByToken: builder.mutation<InterviewResponse, InterviewConductByTokenRequest>({
        query: (interviewInfo) => {
          const { accessToken, ...body } = interviewInfo;
          return {
            url: buildInterviewUrl('/v1/interview/request-patient-interview'),
            method: 'POST',
            body,
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) =>
          error ? [] : [{ type: TagTypes.LatestInterviews, id: result?.interviewTestId + '' }],
      }),

      submitInterviewAnswers: builder.mutation<
        InterviewSubmissionResponse,
        InterviewSubmissionRequest
      >({
        // exclude patientId from request
        query: ({ patientId, ...interviewInfo }) => ({
          url: buildInterviewUrl('/v1/interview/submit-interview-answers'),
          method: 'POST',
          body: interviewInfo,
        }),
        invalidatesTags: buildInterviewSubmissionInvalidatingTags,
      }),

      submitInterviewAnswersByToken: builder.mutation<
        InterviewSubmissionResponse,
        InterviewSubmissionByTokenRequest
      >({
        query: (interviewInfo) => {
          const { accessToken, patientId, ...body } = interviewInfo; // exclude patientId from request
          return {
            url: buildInterviewUrl('/v1/interview/submit-patient-interview-answers'),
            method: 'POST',
            body,
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          };
        },
        invalidatesTags: buildInterviewSubmissionInvalidatingTags,
      }),

      importInterviewOrMonitor: builder.mutation<ImportBuilderResponse, ImportBuilder>({
        query: (importInfo) => ({
          url:
            importInfo?.importType === ImportType.Interview
              ? buildInterviewUrl('/v1/interview-builder/import-interview')
              : buildMonitorUrl('/v1/monitor-builder/import-monitor'),
          method: 'POST',
          body: importInfo,
        }),
      }),

      checkExpireToken: builder.mutation<CheckExpireTokenResponse, CheckExpireTokenRequest>({
        query: (tokenRequest) => ({
          url: buildInterviewUrl('/v1/interview/check-expire-token'),
          method: 'POST',
          body: tokenRequest,
        }),
      }),

      deleteInterviewOrMonitor: builder.mutation<
        DeleteInterviewOrMonitorResponse,
        DeleteInterviewOrMonitorRequest
      >({
        query: ({ interviewResultId, monitorResultId, patientId, type }) => ({
          url:
            type === AssessmentType.Interview
              ? buildInterviewUrl('/v1/interview/delete-interview')
              : buildMonitorUrl('/v1/monitor/delete-monitor'),
          method: 'POST',
          body: type === AssessmentType.Interview ? { interviewResultId } : { monitorResultId },
        }),
        invalidatesTags: [
          TagTypes.LatestInterviews,
          TagTypes.LatestMonitors,
          TagTypes.LatestInterviewDetails,
          TagTypes.LatestMonitorDetails,
          TagTypes.InterviewTypes,
          TagTypes.LatestMonitors,
        ],
      }),

      downloadInterviewOrMonitorReport: builder.mutation<
        DownloadReportResponse,
        DownloadReportRequest
      >({
        query: ({ interviewResultId, monitorResultId, type, pdfName, latestVersion, version }) => ({
          url:
            type === AssessmentType.Interview
              ? buildInterviewUrl('/v1/interview-report/download')
              : buildMonitorUrl('/v1/monitor-report/download'),
          method: 'POST',
          body:
            type === AssessmentType.Interview
              ? { interviewResultId: interviewResultId, pdfName, latestVersion, version }
              : { monitorResultId, pdfName, latestVersion },
        }),
      }),

      getInterviewSettings: builder.query<InterviewSettingsResponse, InterviewSettingsRequest>({
        query: (request) => ({
          url: buildInterviewUrl('/v1/interview/settings'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.InterviewSettings,
      }),

      getPatientInterviewSettings: builder.query<
        InterviewSettingsResponse,
        InterviewSettingsRequest
      >({
        query: ({ accessToken, ...body }) => ({
          url: buildInterviewUrl('/v1/interview/patient-settings'),
          method: 'GET',
          params: { ...body },
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }),
        keepUnusedDataFor: QueryCacheDuration.InterviewSettings,
      }),

      getInterviewReportHistory: builder.query<
        InterviewReportHistoryResponse,
        InterviewReportHistoryRequest
      >({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview-report/history'),
          method: 'GET',
          params: body,
        }),
      }),
      getInterviewSchedule: builder.query<AssessmentScheduleResponse, InterviewScheduleRequest>({
        query: (request) => ({
          url: buildInterviewUrl('/v1/interview/get-interview-schedule'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.Short,
      }),

      requestEditInterview: builder.mutation<EditInterviewResponse, EditInterviewRequest>({
        query: (requestData) => ({
          url: buildInterviewUrl('/v1/interview/edit-interview'),
          method: 'POST',
          body: requestData,
        }),
        invalidatesTags: [TagTypes.LatestInterviews, TagTypes.LatestInterviewDetails],
      }),

      unlockReport: builder.mutation<UnlockReportResponse, UnlockReportRequest>({
        query: ({ interviewResultId, patientId }) => ({
          url: buildInterviewUrl('/v1/interview/unlock-report'),
          method: 'POST',
          body: {
            interviewResultId,
          },
        }),
        invalidatesTags: (result, error, arg) => {
          return [
            TagTypes.LatestInterviews,
            TagTypes.LatestMonitors,
            TagTypes.LatestInterviewDetails,
            TagTypes.LatestMonitorDetails,
            TagTypes.InterviewTypes,
            TagTypes.LatestMonitors,
          ];
        },
      }),

      getFirstAndLastInterviews: builder.query<
        FirstLastInterviewsResponse,
        FirstLastInterviewsRequest
      >({
        query: (request) => ({
          url: buildInterviewUrl('/v1/interview/first-last-interviews'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getInterviewReportByToken: builder.query<AssessmentReport, AssessmentReportByTokenRequest>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-report/clinician-report'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getInterviewTypesForExportData: builder.query<
        ExportStructuredResponse,
        { clinicAccountId: string }
      >({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-builder/export-data-interview-types'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getListStructuredDataCodebook: builder.query<ListStructuredDataCodebookResponse, void>({
        query: () => ({
          url: buildInterviewUrl('/v1/documentation/get-list-structured-data-codebook'),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getDetailStructuredDataCodebook: builder.query<
        DetailStructuredDataCodebookResponse,
        { interviewTypeId?: string }
      >({
        query: (params) => ({
          url: buildInterviewUrl('/v1/documentation/detail-structured-data-codebook'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getListStructuredScoredDataCodebook: builder.query<ListStructuredDataCodebookResponse, void>({
        query: () => ({
          url: buildInterviewUrl('/v1/documentation/get-list-structured-scored-data-codebook'),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getDetailStructuredScoredDataCodebook: builder.query<
        DetailStructuredDataCodebookResponse,
        { interviewTypeId?: string }
      >({
        query: (params) => ({
          url: buildInterviewUrl('/v1/documentation/detail-structured-scored-data-codebook'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      sendPatientReportToEHR: builder.mutation<any, SendPatientReportToEHR>({
        query: (body) => ({
          url: buildInterviewUrl('/v1/patient-report/send-patient-report-to-EHR'),
          method: 'POST',
          body,
        }),
      }),

      getRescoreJobs: builder.query<RescoreJobInterviewResponse, RescoreJobInterviewRequest>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-scoring/rescore-jobs-list'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getRescoreAccounts: builder.query<RescoreAccountsResponse, void>({
        query: () => ({
          url: buildInterviewUrl('/v1/interview-scoring/rescore-accounts'),
          method: 'GET',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getRescorePatients: builder.query<RescorePatientsResponse, RescorePatientsRequest>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-scoring/rescore-patients'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      processRescoreJob: builder.mutation<any, StartRescoreInfoRequest>({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview-scoring/process-rescore'),
          method: 'POST',
          body,
        }),
      }),
      generateReportAndUploadToS3: builder.mutation<any, { patientId: string }>({
        query: (body) => ({
          url: buildInterviewUrl(`/v1/patient-report/generate-report-and-upload-to-s3`),
          method: 'POST',
          body,
        }),
      }),
      generateManagementReportAndUploadToS3: builder.mutation<any, GenerateManagementReport>({
        query: (body) => ({
          url: buildInterviewUrl(
            `/v1/management-report/generate-management-report-and-upload-to-s3`
          ),
          method: 'POST',
          body,
        }),
      }),

      getInterviewList: builder.query<AssessmentListResponseType, AssessmentListRequestType>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-builder/interviews'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getAllInterviewTypePaging: builder.query<any, any>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-builder/interview-types-paging'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      getInterviewTypeInfo: builder.query<
        EditAssessmentTypeInfoResponseDto,
        EditInterviewTypeInfoRequestDto
      >({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-builder/edit-interview-type-info'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),

      addOrEditInterviewType: builder.mutation<any, any>({
        query: (body) => ({
          url: buildInterviewUrl(`/v1/interview-builder/add-edit-interview-type`),
          method: 'POST',
          body,
        }),
      }),

      generateDataFile: builder.mutation<GenerateDataFileResponse, any>({
        query: (body) => ({
          url: buildInterviewUrl(`/AppApi/3/generateBroadData`),
          method: 'POST',
          body,
        }),
      }),

      downloadMasterUsageReport: builder.query<void, MasterUsageReportDownloadRequest>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-report/download-master-usage-report-in-excel'),
          method: 'GET',
          params,
          responseHandler: async (response) => {
            if (response.status === HttpStatusCode.Success) {
              const filenameStartDate = dayjs(params.fromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
              const filenameEndDate = dayjs(params.toDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
              const fileName =
                params.reportType +
                '_usage_report_' +
                filenameStartDate +
                '_' +
                filenameEndDate +
                '.xlsx';
              saveAs(await response.blob(), fileName);
            } else {
              return await response.json();
            }
          },
          cache: 'no-cache',
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      getCacheByKey: builder.query<CacheManagementResponse, CacheManagementRequest>({
        query: (request) => ({
          url: buildInterviewUrl('/v1/cache-management/get-cache-by-key'),
          method: 'GET',
          params: { ...request },
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      resetCacheByKey: builder.mutation<CacheManagementResponse, CacheManagementRequest>({
        query: (body) => ({
          url: buildInterviewUrl('/v1/cache-management/reset-cache-by-key'),
          method: 'POST',
          body,
        }),
      }),
      resetCache: builder.mutation<void, void>({
        query: (body) => ({
          url: buildInterviewUrl('/v1/cache-management/reset-cache'),
          method: 'POST',
          body,
        }),
      }),
      sendInterviewEmailOrTextTheQuestions: builder.mutation<void, { interviewResultId: number }>({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview/interview-send-notification-to-patient'),
          method: 'POST',
          body,
        }),
      }),
      checkResultInProgressOfPacket: builder.mutation<
        CheckResultInProgressOfPacketResponse,
        { interviewResultId?: number; trackerResultId?: number }
      >({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview/check-result-in-progress-of-packet'),
          method: 'POST',
          body,
        }),
      }),
      changeInterviewModeToClinician: builder.mutation<
        ChangeInterviewModeToClinicianResponse,
        { interviewResultId: number }
      >({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview/interview-change-mode-to-clinician'),
          method: 'POST',
          body,
        }),
      }),

      getLatestInterviewResultsByPatient: builder.query<
        LatestInterviewResultsByPatientResponse,
        { patientIds: string }
      >({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview/interview-results-by-patients'),
          method: 'GET',
          params,
        }),
      }),

      exportInterview: builder.query<ExportInterviewResponse, ExportInterviewRequest>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview-builder/export-interview'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      getClinicianNotes: builder.query<ClinicianNotesResponseDto, ClinicianNotesRequestDto>({
        query: (params) => ({
          url: buildInterviewUrl('/v1/interview/get-clinician-notes'),
          method: 'GET',
          params,
        }),
        keepUnusedDataFor: QueryCacheDuration.NoCache,
      }),
      submitClinicianNotes: builder.mutation<
      ClinicianNotesSubmissionResponseDto,
      ClinicianNotesSubmissionRequestDto
      >({
        query: (body) => ({
          url: buildInterviewUrl('/v1/interview/submit-clinician-notes'),
          method: 'POST',
          body,
        }),
      }),
    }),
  });

export const {
  useGetLatestInterviewsQuery,
  useGetLatestInterviewsByIdsQuery,
  useGetInterviewTypesQuery,
  useGetInterviewStatsQuery,
  useGetInterviewSettingsQuery,
  useGetPatientInterviewSettingsQuery,
  useGetInterviewReportHistoryQuery,
  useRequestInterviewMutation,
  useRequestInterviewByTokenMutation,
  useCheckExpireTokenMutation,
  useSubmitInterviewAnswersMutation,
  useSubmitInterviewAnswersByTokenMutation,
  useGetInterviewScheduleQuery,
  useDeleteInterviewOrMonitorMutation,
  useRequestEditInterviewMutation,
  useDownloadInterviewOrMonitorReportMutation,
  useQueryUsageReportQuery,
  useGetFirstAndLastInterviewsQuery,
  useGetInterviewReportByTokenQuery,
  useGetInterviewTypesForExportDataQuery,
  useGetListStructuredDataCodebookQuery,
  useGetDetailStructuredDataCodebookQuery,
  useGetListStructuredScoredDataCodebookQuery,
  useGetDetailStructuredScoredDataCodebookQuery,
  useSendPatientReportToEHRMutation,
  useGenerateReportAndUploadToS3Mutation,
  useUnlockReportMutation,
  useGenerateManagementReportAndUploadToS3Mutation,
  useGetRescoreJobsQuery,
  useGetRescoreAccountsQuery,
  useGetRescorePatientsQuery,
  useProcessRescoreJobMutation,
  useGetInterviewsQuery,
  useImportInterviewOrMonitorMutation,
  useGetInterviewListQuery,
  useGetAllInterviewTypePagingQuery,
  useGetInterviewTypeInfoQuery,
  useAddOrEditInterviewTypeMutation,
  useGenerateDataFileMutation,
  useLazyDownloadMasterUsageReportQuery,
  useGetCacheByKeyQuery,
  useResetCacheByKeyMutation,
  useResetCacheMutation,
  useSendInterviewEmailOrTextTheQuestionsMutation,
  useCheckResultInProgressOfPacketMutation,
  useChangeInterviewModeToClinicianMutation,
  useLazyGetLatestInterviewResultsByPatientQuery,
  useGetClinicianNotesQuery,
  useSubmitClinicianNotesMutation,
} = api;

export const { endpoints: interviewApis } = api;
