import { channel } from './channels';

const syncBroadcastChannelMiddleware = () => {
  return (store: { getState: () => any }) =>
    (next: (arg0: any) => any) =>
    (action: { meta: any; type: string; key?: string }) => {
      const result = next(action);
      const state = store.getState();
      if (action.type === 'auth/setCredentials') {
        channel.postMessage({ type: 'SET_AUTH_STATE', auth: state?.auth });
      }

      if (
        action.type === 'auth/clearCredentials' ||
        (action.type === 'api/executeMutation/fulfilled' &&
          action?.meta?.arg?.endpointName === 'logout')
      ) {
        channel.postMessage({ type: 'CLEAR_AUTH_STATE', auth: state?.auth });
      }

      if (action.key === 'session' && action.type === 'persist/REHYDRATE') {
        channel.postMessage({ type: 'CHECK_AUTH_STATE' });
      }
      return result;
    };
};

export default syncBroadcastChannelMiddleware;
