import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactComponent as ModuleCompletedIconSvg } from '../../../assets/icons/module-completed.svg';
import { ReactComponent as ModuleInProgressIconSvg } from '../../../assets/icons/module-inprogress.svg';
import { ReactComponent as ModuleNotCompletedIconSvg } from '../../../assets/icons/module-not-completed.svg';
import { ReactComponent as ModuleSkipIconSvg } from '../../../assets/icons/module-skip.svg';
import { ModuleStatus } from '../../utils/module';

const IconWrapperButton = styled(IconButton)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  textAlign: 'center',
  padding: '0',
  margin: '0',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
}));

const Title = styled(Typography)(() => ({
  position: 'absolute',
  color: 'white',
}));

export default function ModuleIcon({ title, value, status, onRequestInterview, isActive, disabled }: any) {
  let Icon;
  let isDisabled = disabled;

  switch (status) {
    case ModuleStatus.NotStart:
      Icon = ModuleNotCompletedIconSvg;
      isDisabled = true;
      break;
    case ModuleStatus.InProgress:
      Icon = ModuleInProgressIconSvg;
      break;
    case ModuleStatus.Skipped:
      Icon = ModuleSkipIconSvg;
      isDisabled = true;
      break;
    default:
      Icon = ModuleCompletedIconSvg;
      break;
  }

  return (
    <IconWrapperButton
      onClick={() => {
        onRequestInterview(value);
      }}
      disableRipple
      disabled={isDisabled || isActive}
      sx={{
        marginRight: isActive ? '5px' : '0',
      }}
    >
      <Icon width={isActive ? '100px' : '75px'} height={isActive ? '45px' : '30px'} />
      <Title fontWeight={isActive ? 'bold' : ''}>{title}</Title>
    </IconWrapperButton>
  );
}
