import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../../../app/hooks';
import theme from '../../../../../app/theme';
import RoundedContainer from '../../../../../common/components/RoundedContainer';
import { InterviewMode } from '../../../../../common/utils/interviewsMode';
import { SequenceItemDto } from '../../../../../types/InterviewQuestionTypes';
import { selectInterviewMode } from '../../../interviewSlice';

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

interface BinaryGroupProps {
  item: SequenceItemDto[];
  renderInterviewQuestion: (item: SequenceItemDto, isFromGroup?: boolean) => JSX.Element;
}

export default function TextGroup({ item, renderInterviewQuestion }: BinaryGroupProps) {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const interviewMode = useAppSelector(selectInterviewMode);
  const isPatientInterview = interviewMode === InterviewMode.Patient;
  const showHelpText =
    !isPatientInterview && !item[0].question.isPatientOnly && !!item[0].question.clinicianHelpText;
  const groupQuestionUI = item.map((sequenceItem: SequenceItemDto, index: number) => {
    return (
      <>
        {renderInterviewQuestion(sequenceItem, true)}
        {index < item.length - 1 && (
          <Divider
            sx={{
              width: !isPhone ? '1px' : '100%',
              margin: !isPhone ? '0 10px' : '10px 0',
              backgroundColor: theme.xPalette.lightGrey,
            }}
          />
        )}
      </>
    );
  });

  return (
    <Box key={`group-${item[0].questionId}`}>
      <RoundedContainer
        sx={{
          py: 2,
          mb: 2,
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box width={isDesktop ? '54%' : '100%'}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: item[0].question.questionText!,
            }}
          ></Typography>

          {showHelpText && (
            <Alert severity="info">
              <Typography
                dangerouslySetInnerHTML={{
                  __html: t('interview.form.clinicianHelpText'),
                }}
              ></Typography>
              <Typography
                sx={{ wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={{
                  __html: item[0].question.clinicianHelpText!,
                }}
              ></Typography>
            </Alert>
          )}
        </Box>
        <Box sx={{ width: isDesktop ? '44%' : '100%', display: !isPhone ? 'flex' : undefined }}>
          {groupQuestionUI}
        </Box>
      </RoundedContainer>
    </Box>
  );
}
