import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useAppSelector } from '../../app/hooks';
import DashboardLayout from '../../common/layouts/Dashboard';
import { addBreadcrumb, BreadcrumbType } from '../../common/utils/editBreadcrums';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { InterviewFormatDto } from '../../types/InterviewQuestionTypes';
import { PageProps } from '../../types/PageProps';
import ConductInterviewPage from './ConductInterviewPage';
import ExtendedConductInterviewPage from './extendedConductInterviewUI/ExtendedConductInterviewPage';
import { selectInterview, selectInterviewMode } from './interviewSlice';

export default function ConductInterviewContainer({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { patientId } = useParams();
  const interviewContainerRef = useRef<HTMLDivElement>(null);
  const currentInterview = useAppSelector<InterviewFormatDto>(selectInterview);

  const interviewMode = useAppSelector<string>(selectInterviewMode);
  const isEditInterview = pathname?.includes('edit-interview');
  const newBreadcrumb: BreadcrumbType = {
    text: 'Patient Dashboard',
    link: `/dashboard/patient/${patientId}`,
  };
  const isClinicianInterview = interviewMode === InterviewMode.Clinician || isEditInterview;

  return (
    <DashboardLayout
      breadcrumbs={addBreadcrumb(breadcrumbs!, newBreadcrumb)}
      showBreadcrumb={isClinicianInterview}
      sessionExpiration={isClinicianInterview}
    >
      <Helmet>
        <title>{getPageTitle(t('interview.title'))}</title>
      </Helmet>
      <Container
        maxWidth="xl"
        disableGutters={true}
        ref={interviewContainerRef}
      >
        {currentInterview?.applyNewMiniUI ? (
          <ExtendedConductInterviewPage
            interviewContainerRef={interviewContainerRef}
            isEditInterview={isEditInterview}
            isClinicianInterview={isClinicianInterview}
          />
        ) : (
          <ConductInterviewPage
            interviewContainerRef={interviewContainerRef}
            isEditInterview={isEditInterview}
            isClinicianInterview={isClinicianInterview}
          />
        )}
      </Container>
    </DashboardLayout>
  );
}
