import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../app/hooks';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { QuestionAnswerType, QuestionComponentsMap } from '../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../types/InterviewQuestionTypes';
import { selectInterviewMode } from './interviewSlice';

export default function InterviewQuestion({ question, ...rest }: InterviewQuestionProps) {
  const interviewMode = useAppSelector(selectInterviewMode);
  const isPatientInterview = interviewMode === InterviewMode.Patient;
  const showHelpText =
    !isPatientInterview && !question.isPatientOnly && !!question.clinicianHelpText;
  const showWarningText = !isPatientInterview && question.isClinicianOnly;

  const QuestionComponent = QuestionComponentsMap[question.questionAnswerType];
  if (!QuestionComponent || !question) {
    return (
      <Typography color="red">
        {/* // TODO: Remove warning message once done implenentation */}
        *** Type {question.questionAnswerType} is not implement yet!
      </Typography>
    );
  }

  return question.questionAnswerType === QuestionAnswerType.YbocsCheckList ||
    question.questionAnswerType === QuestionAnswerType.YbocsCheckOther ||
    question.questionAnswerType === QuestionAnswerType.YbocsScale ? (
    <QuestionComponent question={question} {...rest}></QuestionComponent>
  ) : (
    <Box>
      {showWarningText && (
        <Alert severity="warning">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('interview.form.clinicianOnlyWarning'),
            }}
          ></Typography>
        </Alert>
      )}
      <QuestionComponent question={question} {...rest}></QuestionComponent>

      {/* TODO: double check UI */}
      {question.questionHelpText && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography
            dangerouslySetInnerHTML={{
              __html: question.questionHelpText,
            }}
          ></Typography>
        </>
      )}

      {showHelpText && (
        <Alert severity="info">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('interview.form.clinicianHelpText'),
            }}
          ></Typography>
          <Typography
            sx={{ wordBreak: 'break-word' }}
            dangerouslySetInnerHTML={{
              __html: question.clinicianHelpText!,
            }}
          ></Typography>
        </Alert>
      )}
    </Box>
  );
}
