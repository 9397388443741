import { ChangeEvent, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import theme from '../../../../../app/theme';
import { QuestionAnswerType } from '../../../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../../../types/InterviewQuestionTypes';
import useSetWatchQuestion from '../../../hooks/useSetWatchQuestion';

interface SquareRadioIconProps {
  checked?: boolean;
  error?: boolean;
}

const SquareRadioIcon = styled(Box)<SquareRadioIconProps>(({ theme, checked, error }) => {
  return {
    display: 'block',
    width: 24,
    height: 24,
    border: `1.4px solid ${
      checked
        ? theme.palette.secondary.main
        : error
        ? theme.palette.error.main
        : theme.xPalette.lightGrey
    }`,
    borderRadius: 0,
    transition: 'background-color 0.2s',
    backgroundColor: checked ? theme.palette.secondary.main : 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  };
});

export default function MinimizeOptionsQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  optionChangeCallback,
}: InterviewQuestionProps) {
  const optionRef = useRef<HTMLDivElement>(null);
  const initialLoad = useRef<boolean>(true);
  const savedData = field?.value;

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.Options,
    required: !!question.isRequired,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    setValue(field?.name!, value, { shouldValidate: true });
    if (optionChangeCallback) {
      // set DOM attribute
      const selectedIndex = question.possibleAnswers.indexOf(event.target.value);
      optionRef.current?.setAttribute('data-selected-index', '' + (selectedIndex + 1)); // default option is in 1st position so need to adding 1
      // execute parent callback
      optionChangeCallback();
    }

    handleOnBlur();
  };

  const renderItems = () => {
    const { possibleAnswers, displayAnswers } = question;
    const items = [];
    for (let i = 0; i < possibleAnswers.length; i++) {
      const value = possibleAnswers[i];
      const text = ((displayAnswers && displayAnswers[i]) || possibleAnswers[i]).trim();
      items.push(
        <FormControlLabel
          value={value}
          control={
            <Radio
              icon={<SquareRadioIcon error={error} />}
              checkedIcon={<SquareRadioIcon checked={true} error={error} />}
            />
          }
          label={text}
          labelPlacement="start"
        />
      );
    }
    return items;
  };

  useEffect(() => {
    if (savedData && savedData !== '') {
      if (initialLoad.current) {
        initialLoad.current = false;
        setValue(field?.name!, savedData as string, { shouldValidate: true });
        if (optionChangeCallback) {
          // set DOM attribute
          const selectedIndex = question.possibleAnswers.indexOf(savedData);

          optionRef.current?.setAttribute('data-selected-index', '' + (selectedIndex + 1)); // default option is in 1st position so need to adding 1
          // execute parent callback
          optionChangeCallback();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.name, question.possibleAnswers, savedData, setValue]);

  return (
    <Box
      className={
        question.clientDisplayCondition
          ? 'js-has-client-display-condition'
          : 'js-question-answer-options'
      }
      ref={optionRef}
    >
      <Typography variant="body1" textAlign="center">
        {question.name?.includes('current') ? 'Current Episode' : 'Past Episode'}
      </Typography>
      <FormControl error={error}>
        <RadioGroup {...field} onChange={handleChange}>
          {renderItems()}
        </RadioGroup>
      </FormControl>
      {error && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>{helperText}</FormHelperText>
      )}
    </Box>
  );
}
