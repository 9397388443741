import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../app/hooks';
import { selectInterviewMode } from '../../features/interview/interviewSlice';
import { InterviewMode } from '../utils/interviewsMode';
import { ModuleStatus } from '../utils/module';
import ModuleIcon from './icons/ModuleIcon';

interface ModuleListProps {
  modulesForScoring: number[];
  moduleLettersForScoring?: string[];
  currentModule: number;
  inCompleteModule: number;
  activeModule?: number;
  skippedModules?: number[];
  handleModuleChange?: (moduleId: number) => void;
}

export default function ModuleList({
  modulesForScoring,
  moduleLettersForScoring,
  currentModule,
  inCompleteModule,
  activeModule,
  skippedModules,
  handleModuleChange,
}: ModuleListProps) {
  const { pathname } = useLocation();

  const isEditInterview = pathname?.includes('edit-interview');

  const interviewMode = useAppSelector<string>(selectInterviewMode);
  const isClinicianInterview = interviewMode === InterviewMode.Clinician || isEditInterview;
  let items = [];
  let disabled = false;

  for (let index = 0; index < modulesForScoring.length; index++) {
    const currentModuleIndex = modulesForScoring.indexOf(inCompleteModule);
    let status = ModuleStatus.NotStart;
    if (index < currentModuleIndex) {
      status = ModuleStatus.Completed;
      if (skippedModules?.includes(modulesForScoring[index])) {
        status = ModuleStatus.Skipped;
      }
    }
    if (index === currentModuleIndex) {
      status = ModuleStatus.InProgress;
    }

    items.push(
      <Box
        key={`module-${index}`}
        sx={{
          marginRight: '-15px',
        }}
      >
        <ModuleIcon
          title={
            isClinicianInterview && moduleLettersForScoring
              ? moduleLettersForScoring[index]
              : index + 1
          }
          value={modulesForScoring[index]}
          status={status}
          onRequestInterview={handleModuleChange}
          isActive={modulesForScoring[index] === activeModule}
          disabled={disabled}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" mb={2} width="100%">
      {items}
    </Box>
  );
}
