import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { SequenceItemDto } from '../../../../../types/InterviewQuestionTypes';

const StyledTypography = styled(Typography)(() => ({
  margin: '16px 0px',
  fontWeight: 'bold',
}));

export default function MinimizeInfoNoAnswer(item: SequenceItemDto) {
  return (
    <Box sx={{ mt: 2 }}>
      {item.question.questionPrompt && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: item.question.questionPrompt!,
          }}
        ></StyledTypography>
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: item.question.questionText!,
        }}
      ></Typography>
    </Box>
  );
}
