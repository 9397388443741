const prefix = window.location.hostname;
export const channel = new BroadcastChannel(`${prefix}_redux_channel`);

export const ChannelTypes = {
  CLOSE_SESSION_MODAL: 'CLOSE_SESSION_MODAL',
  OPEN_SESSION_MODAL: 'OPEN_SESSION_MODAL',
  CHECK_AUTH_STATE: 'CHECK_AUTH_STATE',
  SET_AUTH_STATE: 'SET_AUTH_STATE',
  CLEAR_AUTH_STATE: 'CLEAR_AUTH_STATE',
  UPDATE_INITIAL_AUTH_STATE: 'UPDATE_INITIAL_AUTH_STATE',
};
