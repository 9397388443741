import { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../app/hooks';
import { logger } from '../../../common/utils/logger';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion } from '../../interview/interviewSlice';
import { getQuestionControlName, StyledClinicianNote as StyledScoreField } from '../interviewUtils';

const StyledTypography = styled(Typography)({
  margin: '16px 0px',
  fontWeight: 'bold',
});

const StyledBox = styled(
  Box,
  {}
)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export default function FormularQuestion({
  question,
  field,
  getValues,
  allQuestions,
}: InterviewQuestionProps) {
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const savedValue = field?.value;
  const initialLoading = useRef<boolean>(true);
  const [displayedValue, setDisplayedValue] = useState<string>('');

  const {
    dependencyQuestions,
    evaluatingFunction,
    dependencyQuestionObj,
    dependencyQuestionTypes,
  } = useMemo(() => {
    let dependencyQuestions: string[] = [];
    let evaluatingFunction: Function = () => undefined;
    let dependencyQuestionObj: any = {};
    let dependencyQuestionTypes: any = {};

    if (question.possibleAnswers.length > 1) {
      dependencyQuestions = question.possibleAnswers[0]
        .split('|')
        .map((id) => getQuestionControlName(id));

      const storedFunction = question.possibleAnswers.slice(1).join(',');
      // eslint-disable-next-line no-new-func
      evaluatingFunction = new Function('ans', `return ${storedFunction}`);

      // for faster retrieval later
      dependencyQuestionObj = dependencyQuestions.reduce(
        (prev, current) => ({
          ...prev,
          [current]: true,
        }),
        {}
      );

      if (allQuestions?.length) {
        dependencyQuestionTypes = allQuestions.reduce((prev, current) => ({
          ...prev,
          [getQuestionControlName(current.id)]: current.question.questionAnswerType,
        }));
        logger.debug('Dependency question types: ', dependencyQuestionTypes);
      }
    }
    return {
      dependencyQuestions,
      evaluatingFunction,
      dependencyQuestionObj,
      dependencyQuestionTypes,
    };
  }, [question, allQuestions]);

  const changeData = field?.value;

  useEffect(() => {
    const { name } = changeData || {};
    // TODO: check name and bind initial value for edit case
    if (name && dependencyQuestionObj[name] && dependencyQuestions && getValues) {
      const answerObj = dependencyQuestions.reduce((prev: string[], current: string) => {
        let answerValue = getValues(current);
        // set default value in case answer is not selected
        if (
          answerValue === '' &&
          dependencyQuestionTypes[current] === QuestionAnswerType.StsScale
        ) {
          answerValue = '0';
        }
        return [...prev, answerValue];
      }, []);
      logger.debug('answer object', answerObj);

      if (answerObj) {
        const computedValue = evaluatingFunction(answerObj);
        logger.debug('computed value: ', computedValue);
        setDisplayedValue(isNaN(computedValue) ? '' : computedValue);
      }
    }
  }, [
    changeData,
    dependencyQuestions,
    dependencyQuestionObj,
    dependencyQuestionTypes,
    evaluatingFunction,
    getValues,
  ]);

  useEffect(() => {
    if (isResetQuestion) {
      setDisplayedValue('');
    }
  }, [field?.name, setDisplayedValue, isResetQuestion]);

  useEffect(() => {
    if (savedValue) {
      if (initialLoading.current) {
        initialLoading.current = false;
        setDisplayedValue(savedValue);
      }
    }
  }, [savedValue]);

  return (
    <StyledBox>
      {question.title && (
        <StyledTypography
          dangerouslySetInnerHTML={{
            __html: question.title,
          }}
        ></StyledTypography>
      )}
      <StyledScoreField
        sx={{ maxWidth: '148px' }}
        inputProps={{ style: { textAlign: 'center' } }}
        value={displayedValue}
        disabled
      />
    </StyledBox>
  );
}
