import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as ArrowSwapIcon } from '../../../assets/icons/arrow-swap-horizontal.svg';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../../interview/interviewSlice';
import useSetWatchQuestion from '../hooks/useSetWatchQuestion';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px',
  borderRadius: '8px',
  width: '50%',

  '& path': {
    stroke: theme.xPalette.white,
  },
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
}));

enum AnswerUnit {
  Imperial = 'imperial',
  Metrics = 'metrics',
}

export default function WeightQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  answerUnit,
}: InterviewQuestionProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [answerUnitName, setAnswerUnitName] = useState<string>(answerUnit);
  const [weightValue, setWeightValue] = useState<{ valuePounds?: string; valueKilograms?: string }>(
    {
      valuePounds: '',
      valueKilograms: '',
    }
  );
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const checkAnswerUnit = answerUnitName === AnswerUnit.Imperial;
  const savedValue = field?.value;
  const dispatch = useAppDispatch();
  const shouldRequestInterviewRef = useRef(true);

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.Weight,
    required: !!question.isRequired,
  });

  const onValueChanged = (event: any) => {
    const unit = event.target?.id;
    const value = event.target?.value || '';
    let weightValueChange = { [unit]: value };

    setWeightValue(weightValueChange);
    setValue(field?.name!, weightValueChange);
    dispatch(setResetQuestion({ reset: false }));
  };

  useEffect(() => {
    if (savedValue) {
      const { valuePounds, valueKilograms } = savedValue;
      if (valuePounds || valueKilograms) {
        if (shouldRequestInterviewRef.current) {
          shouldRequestInterviewRef.current = false;
          setWeightValue({
            valuePounds,
            valueKilograms,
          });
          setAnswerUnitName(valueKilograms ? AnswerUnit.Metrics : AnswerUnit.Imperial);
        }
      }
    }
  }, [savedValue]);

  useEffect(() => {
    if (isResetQuestion) {
      setWeightValue({
        valuePounds: '',
        valueKilograms: '',
      });
    }
  }, [isResetQuestion]);

  return (
    <Box>
      <FormControl error={error} variant="standard">
        <Typography
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></Typography>
        <Box
          sx={{
            width: matches ? '100%' : 400,
            display: 'flex',
            justifyContent: 'space-around',
            mt: 2,
            columnGap: 1,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              columnGap: 1,
            }}
          >
            {checkAnswerUnit ? (
              <StyledTextField
                id="valuePounds"
                error={error}
                label={t('interview.form.poundsLabel')}
                variant="outlined"
                onChange={onValueChanged}
                onBlur={() => handleOnBlur()}
                value={weightValue?.valuePounds || ''}
              />
            ) : (
              <StyledTextField
                id="valueKilograms"
                error={error}
                label={t('interview.form.kilogramsLabel')}
                variant="outlined"
                onChange={onValueChanged}
                onBlur={() => handleOnBlur()}
                value={weightValue?.valueKilograms || ''}
              />
            )}
          </Box>
          <StyledButton
            type="button"
            variant="contained"
            startIcon={<ArrowSwapIcon fontSize="small" />}
            onClick={() => {
              if (checkAnswerUnit) {
                setAnswerUnitName(AnswerUnit.Metrics);
              } else {
                setAnswerUnitName(AnswerUnit.Imperial);
              }
              setValue(field?.name!, {});
              setWeightValue({ valuePounds: '', valueKilograms: '' });
            }}
          >
            {checkAnswerUnit
              ? t('interview.form.metricUnitsWeight')
              : t('interview.form.imperialUnitsWeight')}
          </StyledButton>
        </Box>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
