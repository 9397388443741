import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  useGetInterviewSettingsQuery,
  useRequestInterviewMutation,
} from '../../app/services/interview';
import { useGetPatientQuery } from '../../app/services/patient';
import Loading from '../../common/components/Loading';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import DashboardLayout from '../../common/layouts/Dashboard';
import { editBreadcrumbs, ReplaceType } from '../../common/utils/editBreadcrums';
import { InterviewSettingsViewName } from '../../common/utils/interviewSettings';
import { InterviewMode } from '../../common/utils/interviewsMode';
import { getPageTitle } from '../../common/utils/pageUtils';
import { PageProps } from '../../types/PageProps';
import { ResponseError } from '../../types/ResponseError';
import ErrorPage from '../error/ErrorPage';
import ReadyOrResumeInterview from './ReadyOrResumeInterview';
import {
  selectInterviewMode,
  selectReportType,
  selectTrialInterview,
  setCurrentInterview,
  setPatientInterviewData,
} from './interviewSlice';

// TODO: move to common place
export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.xPalette.white,
  padding: '0px 24px',
  borderRadius: '16px',
  '&.MuiBox-root:first-of-type': {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  '&.MuiBox-root:last-child': {
    margin: '16px 0',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}));

export default function StartInterviewPage({ breadcrumbs }: PageProps) {
  const { t } = useTranslation();
  const { patientId, interviewResultId: inputInterviewResultId } = useParams();
  const dispatch = useAppDispatch();
  const interviewMode = useAppSelector(selectInterviewMode);
  const reportType = useAppSelector(selectReportType);
  const trialInterview = useAppSelector(selectTrialInterview);
  const [requestInterview] = useRequestInterviewMutation();
  const [pageErrors, setPageErrors] = useState<string[]>([]);

  const replacePath: ReplaceType[] = [{ param: ':patientId', value: patientId! }];
  const interviewResultId = parseInt(inputInterviewResultId!);
  const { state } = useLocation();
  const { resumeInterview, cancelUrl, editInterview } = !!state && state;

  const { data: infoData, error } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const {
    data: accountSettings,
    error: settingError,
    refetch,
  } = useGetInterviewSettingsQuery(
    interviewResultId && interviewMode
      ? {
          interviewTestId: interviewResultId,
          interviewType: !editInterview ? interviewMode : InterviewMode.Clinician,
          viewName: resumeInterview
            ? InterviewSettingsViewName.Resume
            : InterviewSettingsViewName.Start,
        }
      : skipToken
  );

  useEffect(() => {
    const loadInterview = async () => {
      try {
        const responseData = await requestInterview({
          interviewTestId: interviewResultId,
        }).unwrap();

        if (responseData) {
          dispatch(setCurrentInterview({ interviewData: responseData }));
          dispatch(
            setPatientInterviewData({
              data: {
                interviewTestId: interviewResultId,
                accessToken: responseData.patientInterviewToken!,
                lastActivityTs: Date.now(),
              },
            })
          );
        }

        setPageErrors([]);
      } catch (e) {
        const {
          data: { error },
        } = e as ResponseError;
        setPageErrors(error);
      }
    };

    loadInterview();
  }, [dispatch, requestInterview, interviewResultId]);

  if (error || settingError) {
    return <ErrorPage statusCode={((error || settingError) as FetchBaseQueryError).status} />;
  }

  if ((!interviewMode || !reportType) && pageErrors.length === 0) {
    refetch();
  }

  return (
    <DashboardLayout breadcrumbs={editBreadcrumbs(breadcrumbs, ...replacePath)}>
      <Helmet>
        <title>{getPageTitle(t('interview.title'))}</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={true}>
        <StyledBox>
          <PatientInfoCard data={infoData!} />
        </StyledBox>
        <StyledBox>
          {!accountSettings && pageErrors.length === 0 ? (
            <Loading />
          ) : (
            <ReadyOrResumeInterview
              interviewMode={interviewMode}
              reportType={reportType}
              isTrialRun={trialInterview}
              resumeInterview={resumeInterview}
              accountSettings={accountSettings}
              pageErrors={pageErrors}
              cancelUrl={cancelUrl}
              editInterview={editInterview}
            />
          )}
        </StyledBox>
      </Container>
    </DashboardLayout>
  );
}
