const titleSuffix = process.env.REACT_APP_TITLE_SUFFIX || 'Proem Health';

const getPageTitle = (pageName?: string) =>
  pageName ? `${pageName} | ${titleSuffix}` : titleSuffix;

export { getPageTitle };

export const RouteReportType = {
  QUESTION: 'question',
  SCREENER_PAGE: 'screenerPage',
  YBOCS_PAGE: 'ybocsPage',
};
