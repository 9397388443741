import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DefaultLanguage, populateLanguage } from '../../../common/utils/languages';
import { parseLegal } from '../../../common/utils/reportUtils';
import { Assessment } from '../../../types/ReportType';

interface ReportTitlePageProps {
  assessment: Assessment;
}

const StyledPTagTop = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '20px',
  textAlign: 'center',
}));

const StyledPTagBottom = styled(Typography)(() => ({
  marginBottom: '10px',
}));

export default function ReportTitlePage({ assessment }: ReportTitlePageProps) {
  const { t } = useTranslation();
  const languageDisplay = populateLanguage(assessment.language || DefaultLanguage.English);

  let revisionDatePretty = '';
  let revisionDateNormal = '';

  if (assessment.revisionDate) {
    const revisionDate = dayjs(assessment.revisionDate);
    revisionDatePretty = revisionDate.format('MMMM D, YYYY');
    revisionDateNormal = revisionDate.format('MM/DD/YY');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '1200px',
      }}
    >
      <Box>
        <Typography
          sx={{ textAlign: 'center', fontSize: '40px', fontWeight: 'bold', margin: '50px 0' }}
          variant="h1"
        >
          {assessment.title}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', marginBottom: '50px' }}
        >
          {assessment.subTitle}
        </Typography>
        <StyledPTagTop>
          {languageDisplay} {t('assessmentReport.titlePage.version')} {assessment.versionNumber}
        </StyledPTagTop>
        <StyledPTagTop>{t('assessmentReport.titlePage.for')}</StyledPTagTop>
        <StyledPTagTop>{t('assessmentReport.titlePage.dsm5')}</StyledPTagTop>
      </Box>

      <Box sx={{ pageBreakInside: 'avoid' }}>
        <Box
          sx={{
            '&.MuiBox-root .MuiTypography-root': {
              fontSize: '12px',
              fontWeight: 500,
            },
          }}
        >
          {assessment.legal && (
            <StyledPTagBottom
              dangerouslySetInnerHTML={{
                __html: parseLegal(assessment.legal),
              }}
            ></StyledPTagBottom>
          )}
          {assessment.displayDisclaimer && !assessment.applyNewMiniUI && (
            <>
              <StyledPTagBottom sx={{ textAlign: 'center' }}>
                {t('assessmentReport.titlePage.disclaimer')}
              </StyledPTagBottom>
              <StyledPTagBottom>
                {t('assessmentReport.titlePage.disclaimerDesc.p1')}
              </StyledPTagBottom>
              <StyledPTagBottom>
                {t('assessmentReport.titlePage.disclaimerDesc.p2')}
              </StyledPTagBottom>
            </>
          )}
        </Box>
        <StyledPTagBottom sx={{ fontWeight: 'bold' }}>
          {assessment.name}{' '}
          {assessment.revisionDate && (
            <>
              ({revisionDatePretty})({revisionDateNormal})
            </>
          )}
        </StyledPTagBottom>
      </Box>
    </Box>
  );
}
