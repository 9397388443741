import { PageBreadcrumb } from '../../types/PageBreadcrumb';

export interface ReplaceType {
  param: string;
  value: string;
}
export const editBreadcrumbs = (
  breadcrumbs: PageBreadcrumb[] | undefined,
  ...replaceParams: ReplaceType[]
) => {
  const newBreadCrumbs = breadcrumbs?.map((breadcrumb) => {
    replaceParams.forEach((path) => {
      if (breadcrumb.link && breadcrumb.link.includes(path.param)) {
        breadcrumb = {
          ...breadcrumb,
          link: breadcrumb.link.replace(path.param, path.value),
        };
      }
    });
    return breadcrumb;
  });
  return newBreadCrumbs;
};

export interface BreadcrumbType {
  text: string;
  link: string;
}

export const addBreadcrumb = (breadcrumbs: PageBreadcrumb[], addItem: BreadcrumbType) => {
  if (breadcrumbs?.length < 3) {
    breadcrumbs?.splice(1, 0, addItem);
  } else {
    breadcrumbs?.splice(1, 1, addItem);
  }
  return breadcrumbs;
};
