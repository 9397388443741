import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import useSetWatchQuestion from '../hooks/useSetWatchQuestion';
import { StyledButton, StyledNumberField } from './NumberQuestion';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: '8px',

  '&:last-of-type': {
    color: theme.xPalette.lightGrey,
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
  minWidth: '80px',
}));

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  margin: '16px 0',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
}));

enum TimeEventName {
  IncreaseHour = 'increaseHour',
  DecreaseHour = 'decreaseHour',
  IncreaseMinute = 'increaseMinute',
  DecreaseMinute = 'decreaseMinute',
}

export default function TimeQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
}: InterviewQuestionProps) {
  const { t } = useTranslation();
  const [inputHour, setInputHour] = useState<string>('0');
  const [inputMinute, setInputMinute] = useState<string>('0');
  const savedValue = field?.value;
  const shouldRequestInterviewRef = useRef(true);

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.Time,
    required: !!question.isRequired,
  });

  const valueSetterMap: { [key: string]: (value: any) => void } = {
    valueHour: setInputHour,
    valueMinute: setInputMinute,
  };

  const handleIncreaseOrDecrease = (valueName: any) => {
    const value: any = {
      increaseHour: (parseInt(inputHour) + 1).toString(),
      decreaseHour: (parseInt(inputHour) - 1).toString(),
      increaseMinute: (parseInt(inputMinute) + 1).toString(),
      decreaseMinute: (parseInt(inputMinute) - 1).toString(),
    };
    const setTimeValue = value[valueName];

    if (valueName === TimeEventName.IncreaseHour || valueName === TimeEventName.DecreaseHour) {
      setInputHour(setTimeValue);
    }

    if (valueName === TimeEventName.IncreaseMinute || valueName === TimeEventName.DecreaseMinute) {
      setInputMinute(setTimeValue);
    }
  };

  const handleChange = (event: any) => {
    const setter = valueSetterMap[event.target.id];
    if (setter) {
      setter(event.target.value);
    }
    const timeValue = `${inputHour || 0}:${inputMinute || 0}`;
    setValue(field?.name!, timeValue);
  };

  useEffect(() => {
    if (savedValue) {
      const values = savedValue.split(':');
      if (shouldRequestInterviewRef.current) {
        if (values[0] !== '0' || values[1] !== '00') {
          shouldRequestInterviewRef.current = false;
          setInputHour('' + (parseInt(values[0]) || 0));
          setInputMinute('' + (parseInt(values[1]) || 0));
        }
      }
    }

    const timeValue = `${inputHour || 0}:${inputMinute || 0}`;
    setValue(field?.name!, timeValue);
  }, [field?.name, inputHour, inputMinute, savedValue, setValue]);

  return (
    <Box sx={{ my: 2 }}>
      <FormControl error={error} variant="standard">
        {question.questionPrompt && (
          <StyledTitle
            dangerouslySetInnerHTML={{
              __html: question.questionPrompt,
            }}
          ></StyledTitle>
        )}
        <StyledTitle
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></StyledTitle>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              columnGap: 2,
            }}
          >
            <StyledTypography>{t('interview.form.hourLabel')}</StyledTypography>
            <StyledBox>
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<MinusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeEventName.DecreaseHour)}
                onBlur={() => handleOnBlur()}
              ></StyledButton>
              <StyledNumberField
                id="valueHour"
                variant="outlined"
                error={error}
                onChange={handleChange}
                onBlur={() => handleOnBlur()}
                value={inputHour}
              />
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<PlusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeEventName.IncreaseHour)}
                onBlur={() => handleOnBlur()}
              ></StyledButton>
            </StyledBox>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              columnGap: 2,
            }}
          >
            <StyledTypography>{t('interview.form.minuteLabel')}</StyledTypography>
            <StyledBox>
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<MinusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeEventName.DecreaseMinute)}
                onBlur={() => handleOnBlur()}
              ></StyledButton>
              <StyledNumberField
                id="valueMinute"
                variant="outlined"
                error={error}
                onChange={handleChange}
                onBlur={() => handleOnBlur()}
                value={inputMinute}
              />
              <StyledButton
                type="button"
                variant="outlined"
                startIcon={<PlusIcon fontSize="medium" />}
                onClick={() => handleIncreaseOrDecrease(TimeEventName.IncreaseMinute)}
                onBlur={() => handleOnBlur()}
              ></StyledButton>
            </StyledBox>
          </Box>
        </Box>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
