import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as ArrowSwapIcon } from '../../../assets/icons/arrow-swap-horizontal.svg';
import { QuestionAnswerType } from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectResetQuestion, setResetQuestion } from '../../interview/interviewSlice';
import useSetWatchQuestion from '../hooks/useSetWatchQuestion';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '16px',
  borderRadius: '8px',
  width: '50%',

  '& path': {
    stroke: theme.xPalette.white,
  },
}));

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
}));

enum AnswerUnit {
  Imperial = 'imperial',
  Metrics = 'metrics',
}

export default function HeightQuestion({
  question,
  field,
  error,
  helperText,
  setValue,
  answerUnit,
}: InterviewQuestionProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [answerUnitName, setAnswerUnitName] = useState<string>(answerUnit);
  const [heightValue, setHeightValue] = useState<{
    valueFeet?: string;
    valueInches?: string;
    valueCentimeters?: string;
  }>({
    valueFeet: '',
    valueInches: '',
    valueCentimeters: '',
  });
  const checkAnswerUnit = answerUnitName === AnswerUnit.Imperial;
  const savedValue = field?.value;
  const isResetQuestion = useAppSelector<boolean>(selectResetQuestion);
  const dispatch = useAppDispatch();
  const shouldRequestInterviewRef = useRef(true);

  const { handleOnBlur } = useSetWatchQuestion({
    name: field?.name!,
    type: QuestionAnswerType.Height,
    required: !!question.isRequired,
  });

  const onValueChanged = (event: any) => {
    const unit = event.target?.id;
    const value = event.target?.value || '';

    let heightValueChange = {};
    if (unit === 'valueCentimeters') {
      heightValueChange = { [unit]: value };
    }

    if (unit !== 'valueCentimeters') {
      delete heightValue.valueCentimeters;
      heightValueChange = {
        ...heightValue,
        [unit]: value,
      };
    }

    setHeightValue(heightValueChange);
    setValue(field?.name!, heightValueChange);

    dispatch(setResetQuestion({ reset: false }));
  };

  useEffect(() => {
    if (savedValue) {
      const { valueCentimeters, valueFeet, valueInches } = savedValue;
      if (valueFeet || valueInches || valueCentimeters) {
        if (shouldRequestInterviewRef.current) {
          shouldRequestInterviewRef.current = false;
          setHeightValue({
            valueFeet,
            valueInches,
            valueCentimeters,
          });
          setAnswerUnitName(valueCentimeters ? AnswerUnit.Metrics : AnswerUnit.Imperial);
        }
      }
    }
  }, [savedValue]);

  useEffect(() => {
    if (isResetQuestion) {
      setHeightValue({
        valueFeet: '',
        valueInches: '',
        valueCentimeters: '',
      });
    }
  }, [isResetQuestion]);

  return (
    <Box>
      <FormControl error={error} variant="standard">
        <Typography
          dangerouslySetInnerHTML={{
            __html: question.questionText!,
          }}
        ></Typography>
        <Box
          sx={{
            maxWidth: 400,
            width: matches ? '100%' : 400,
            display: 'flex',
            justifyContent: 'space-around',
            columnGap: 1,
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              columnGap: 1,
            }}
          >
            {checkAnswerUnit ? (
              <>
                <TextField
                  id="valueFeet"
                  label={t('interview.form.feetLabel')}
                  variant="outlined"
                  error={error}
                  onChange={onValueChanged}
                  onBlur={() => handleOnBlur()}
                  value={heightValue?.valueFeet || ''}
                />
                <TextField
                  id="valueInches"
                  label={t('interview.form.inchesLabel')}
                  variant="outlined"
                  error={error}
                  onChange={onValueChanged}
                  onBlur={() => handleOnBlur()}
                  value={heightValue?.valueInches || ''}
                />
              </>
            ) : (
              <StyledTextField
                id="valueCentimeters"
                label={t('interview.form.centimetersLabel')}
                variant="outlined"
                error={error}
                onChange={onValueChanged}
                onBlur={() => handleOnBlur()}
                value={heightValue?.valueCentimeters || ''}
              />
            )}
          </Box>
          <StyledButton
            type="button"
            variant="contained"
            startIcon={<ArrowSwapIcon fontSize="small" />}
            onClick={() => {
              if (checkAnswerUnit) {
                setAnswerUnitName(AnswerUnit.Metrics);
              } else {
                setAnswerUnitName(AnswerUnit.Imperial);
              }
              setValue(field?.name!, {});
              setHeightValue({
                valueFeet: '',
                valueInches: '',
                valueCentimeters: '',
              });
            }}
          >
            {checkAnswerUnit
              ? t('interview.form.metricUnitsHeight')
              : t('interview.form.imperialUnitsHeight')}
          </StyledButton>
        </Box>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
