import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isValidInterviewConductRoute } from '../../app/routes';
import {
  useRequestInterviewByTokenMutation,
  useRequestInterviewMutation,
  useSubmitInterviewAnswersByTokenMutation,
  useSubmitInterviewAnswersMutation,
} from '../../app/services/interview';
import { useGetPatientQuery } from '../../app/services/patient';
import Loading from '../../common/components/Loading';
import PatientInfoCard from '../../common/components/PatientInfoCard';
import RoundedContainer from '../../common/components/RoundedContainer';
import { HttpStatusCode } from '../../common/utils/httpStatusCode';
import { AnswerUnit, QuestionAnswerType } from '../../common/utils/questionAnswerTypes';
import { PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS } from '../../common/utils/securityConfig';
import { RegexValidation } from '../../common/utils/validationUtils';
import {
  ClinicianNoteVersionsType,
  InterviewAnswerDto,
  InterviewAnswerValueDto,
  InterviewFormatDto,
  SequenceItemDto,
} from '../../types/InterviewQuestionTypes';
import {
  errorAnswerMessagesType,
  InterviewResponse,
  InterviewSubmissionByTokenRequest,
  InterviewSubmissionRequest,
  InterviewSubmissionResponse,
} from '../../types/InterviewRequest';
import { PatientMonitorData } from '../../types/MonitorTypes';
import { QuestionError, QuestionResponseError, ResponseError } from '../../types/ResponseError';
import { selectIsAuthenticated } from '../auth/authSlice';
import ErrorPage from '../error/ErrorPage';
import InterviewSummaryError from '../error/InterviewSummaryError';
import { selectPatientMonitorData, clearPatientMonitorData } from '../monitor/monitorSlide';
import InterviewQuestion from './InterviewQuestion';
import usePatientSessionExpiration from './hooks/usePatientSessionExpiration';
import {
  clearCurrentInterview,
  clearPatientInterviewData,
  PatientInterviewData,
  selectAnswerUnit,
  selectAssessmentUrl,
  selectCanNavigateBack,
  selectErrorAnswerQuestions,
  selectInterview,
  selectPatientInterviewData,
  selectTimeframeInDays,
  setCurrentInterview,
  setErrorFromResponse,
  setPatientInterviewData,
  setResetQuestion,
  updateCurrentInterview,
} from './interviewSlice';
import {
  checkTimeframe,
  ClinicianNoteField,
  getQuestionControlName,
  InterviewAction,
  InterviewReportType,
  StyledClinicianNote,
} from './interviewUtils';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '50%',
  padding: '12px 0px',
  borderRadius: '10px',
  [theme.breakpoints.up('md')]: {
    width: '20%',
    minWidth: '200px',
  },
}));

const getRelatedClientSideScripts = (
  sequenceItems: SequenceItemDto[]
): { script: string; questionId: number }[] => {
  return sequenceItems
    .filter((item) => !!item.question.clientDisplayCondition)
    .map((item) => ({
      questionId: item.questionId,
      script: item.question.clientDisplayCondition as string,
    }));
};

const getRelatedQuestions = (
  sequenceItems: SequenceItemDto[]
): { disabled: boolean; questionId: number; relatedQuestionId?: string }[] => {
  return sequenceItems
    .filter((item) => !!item.question.relatedQuestionIdForAnswerValues)
    .map((item) => ({
      questionId: item.questionId,
      relatedQuestionId: item.question.relatedQuestionIdForAnswerValues,
      disabled: true,
    }));
};

const buildDefaultValueAndValidationObject = (
  t: TFunction,
  currentSequenceItems?: SequenceItemDto[]
) => {
  const validationData: any = {};
  const defaultValues: any = {};
  if (currentSequenceItems) {
    currentSequenceItems.forEach(({ question, questionId }: SequenceItemDto) => {
      const key = getQuestionControlName(questionId);
      defaultValues[key] = '';

      if (
        question.isRequired &&
        question.questionAnswerType !== QuestionAnswerType.InfoNoAnswer &&
        question.questionAnswerType !== QuestionAnswerType.Formula
      ) {
        validationData[key] = yup.string().required(t('interview.error.blankAnswer'));
      }
      // TODO: move if statements to mapping object
      if (question.questionAnswerType === QuestionAnswerType.Height) {
        defaultValues[key] = {
          valueFeet: '',
          valueInches: '',
          valueCentimeters: '',
        };
        validationData[key] = yup
          .object({
            valueFeet: yup.string().nullable(),
            valueInches: yup.string().nullable(),
            valueCentimeters: yup.string().nullable(),
          })
          .test(
            'is-optional-height',
            t('interview.error.blankAnswer'),
            function ({ valueCentimeters, valueFeet, valueInches }) {
              if (!valueCentimeters) {
                if (!valueFeet || !valueInches) {
                  return false;
                }
                return true;
              }
              return true;
            }
          );
      }
      if (question.questionAnswerType === QuestionAnswerType.Weight) {
        defaultValues[key] = {
          valuePounds: '',
          valueKilograms: '',
        };
        validationData[key] = yup
          .object({
            valuePounds: yup.string().nullable(),
            valueKilograms: yup.string().nullable(),
          })
          .test(
            'is-optional-weight',
            t('interview.error.blankAnswer'),
            function ({ valuePounds, valueKilograms }) {
              if (!valueKilograms) {
                if (!valuePounds) {
                  return false;
                }
                return true;
              }
              return true;
            }
          );
      }
      if (question.questionAnswerType === QuestionAnswerType.TimeDayMonth) {
        validationData[key] = yup
          .string()
          .matches(RegexValidation.NumberOrEmptyString, t('interview.error.numberOnly'));
      }
      if (question.questionAnswerType === QuestionAnswerType.Number) {
        if (question.enableTimeframeChecking) {
          validationData[key] = yup
            .number()
            .min(0, t('interview.error.positiveNumberOnly'))
            .typeError(t('interview.error.numberOnly'))
            .required(t('interview.error.blankAnswer'));
        } else {
          validationData[key] = yup
            .number()
            .typeError(t('interview.error.numberOnly'))
            .required(t('interview.error.blankAnswer'));
        }
      }
      if (question.questionAnswerType === QuestionAnswerType.Grid) {
        let schema = question.isRequired
          ? yup.mixed().required(t('interview.error.blankAnswer'))
          : undefined;
        validationData[key] = schema;
      }
      if (question.questionAnswerType === QuestionAnswerType.Date) {
        defaultValues[key] = null;
        validationData[key] = question.isRequired
          ? yup.string().nullable().required(t('interview.error.blankAnswer'))
          : yup.string().nullable();
      }
    });
  }
  return { validationData, defaultValues };
};

const getParentQuestions = (currentSequenceItems?: SequenceItemDto[]) => {
  return (
    (currentSequenceItems &&
      currentSequenceItems
        .filter((item) => !!item.question.relatedQuestionIdForAnswerValues)
        .map((item) => {
          return (
            item.question.relatedQuestionIdForAnswerValues &&
            parseInt(item.question.relatedQuestionIdForAnswerValues!)
          );
        })) ||
    []
  );
};

const findRelativeStsEventQuestion = (currentSequenceItems?: SequenceItemDto[]) => {
  return (
    (currentSequenceItems &&
      currentSequenceItems
        .filter((item) => item.question.questionAnswerType === QuestionAnswerType.StsEvent)
        .map((item) => {
          return item.question.name?.slice(0, 2);
        })) ||
    []
  );
};

const convertSubmitAnswers = (
  interviewResultId: number,
  submitAnswers: any,
  currentSequenceItems?: SequenceItemDto[]
): InterviewSubmissionRequest => {
  const answers: InterviewAnswerDto[] = [];
  currentSequenceItems?.forEach((item) => {
    const submitAnswer = submitAnswers[getQuestionControlName(item.questionId)];
    let answer: any = {};
    if (typeof submitAnswer === 'object') {
      answer = { ...submitAnswer };
    } else {
      answer = { value: submitAnswer };
    }

    answers.push({
      questionId: item.questionId,
      answer,
    });
  });

  return {
    interviewTestId: interviewResultId,
    clinicianNotes: submitAnswers[ClinicianNoteField],
    answers,
  };
};

const StyledHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  height: '40px',
  color: theme.xPalette.black,
  padding: '8px 24px',
  fontWeight: theme.typography.h1.fontWeight,
  backgroundColor: theme.xPalette.background,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '70px',
  },
}));

const StyledCheckListAnswerBox = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.h1.fontWeight,
  backgroundColor: theme.xPalette.background,
  borderBottom: 0,
  width: '10%',
  minWidth: '50px',
  textAlign: 'center',
}));

const StyledQuestionNameHeader = styled(Box)(() => ({
  width: '70%',
}));

const StyledAnswerHeader = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
}));

interface Props {
  interviewContainerRef: RefObject<HTMLDivElement>;
  isEditInterview: boolean;
  isClinicianInterview: boolean;
}

export default function ConductInterviewPage({
  interviewContainerRef,
  isEditInterview,
  isClinicianInterview,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    patientId,
    interviewResultId: inputInterviewResultId,
    reportType: reportTypePath,
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageErrors, setPageErrors] = useState<string[]>([]);
  const [dataLoadError, setDataLoadError] = useState<boolean>(false);
  const [hiddenQuestions, setHiddenQuestions] = useState<any>({});
  const [disableQuestions, setDisableQuestions] = useState<any>({});
  const [isInterviewCompleted, setInterviewCompleted] = useState<boolean>(false);
  const [nextInterviewResultId, setNextInterviewResultId] = useState<number>(0);
  const [nextTrackerResultId, setNextTrackerResultId] = useState<number>(0);

  const [currentReportType, setCurrentReportType] = useState<string>('');
  const shouldRequestInterviewRef = useRef(true);

  const { data: patientInfo, error: patientGetError } = useGetPatientQuery(
    patientId
      ? {
          patientId,
        }
      : skipToken
  );

  const [requestInterview] = useRequestInterviewMutation();
  const [requestInterviewByToken] = useRequestInterviewByTokenMutation();
  const [submitInterviewAnswers] = useSubmitInterviewAnswersMutation();
  const [submitInterviewAnswersByToken] = useSubmitInterviewAnswersByTokenMutation();
  const currentInterview = useAppSelector<InterviewFormatDto>(selectInterview);
  const answerUnit = useAppSelector<AnswerUnit>(selectAnswerUnit);
  const canNavigateBack = useAppSelector<boolean>(selectCanNavigateBack);
  const errorAnswerMessages = useAppSelector<errorAnswerMessagesType>(selectErrorAnswerQuestions);
  const patientInterviewData = useAppSelector<PatientInterviewData>(selectPatientInterviewData);
  const isAuthenticated = useAppSelector<boolean>(selectIsAuthenticated);
  const timeframeInDays = useAppSelector<number>(selectTimeframeInDays);
  const patientMonitorData = useAppSelector<PatientMonitorData>(selectPatientMonitorData);
  const assessmentUrl = useAppSelector<string>(selectAssessmentUrl);
  const currentModule = (currentInterview?.modules && currentInterview.modules[0]) || {};
  const currentSequenceItems = currentModule.sequenceOrders;
  const previousAnswers = currentInterview?.previousAnswers;
  const previousClinicianNotes = currentInterview?.previousClinicianNotes;
  const previousClinicianNoteVersions = currentInterview?.previousClinicianNoteVersions;

  usePatientSessionExpiration({
    isClinician: isClinicianInterview,
    assessmentUrl,
  });

  const interviewResultId =
    parseInt(inputInterviewResultId!) || patientInterviewData.interviewTestId;

  const setListResultIds = (interviewResultId: string | number) => {
    const savedListResultIds = localStorage.getItem('listResultIds');
    let parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
    if (!parsedListResultIds?.length) {
      parsedListResultIds = [];
      parsedListResultIds.push(interviewResultId);
    } else if (!parsedListResultIds.includes(interviewResultId)) {
      parsedListResultIds.push(interviewResultId);
    }
    localStorage.setItem('listResultIds', JSON.stringify(parsedListResultIds));
  };

  useEffect(() => {
    setListResultIds(interviewResultId?.toString());
    if (patientInterviewData?.interviewTestId) {
      setListResultIds(patientInterviewData.interviewTestId?.toString());
    }
    // eslint-disable-next-line
  }, []);

  const showMiniScreenerModule = [
    InterviewReportType.MINI,
    InterviewReportType.Screener,
    InterviewReportType.SdsSts,
  ].includes(currentInterview?.reportType!);
  const showYbocsModule = [InterviewReportType.YBOCS, InterviewReportType.CYBOCS].includes(
    currentInterview?.reportType!
  );
  const isYbocsCheckList =
    showYbocsModule &&
    currentSequenceItems?.find(
      (sequence) => sequence.question?.questionAnswerType === QuestionAnswerType.YbocsCheckList
    );

  const isYbocsTargetSymptomListText =
    showYbocsModule &&
    currentSequenceItems?.find(
      (sequence) =>
        sequence.question?.questionAnswerType === QuestionAnswerType.TargetSymptomListText
    );

  const isYbocsScale =
    showYbocsModule &&
    currentSequenceItems?.find(
      (sequence) => sequence.question?.questionAnswerType === QuestionAnswerType.YbocsScale
    );

  const showSdsStdModule = [InterviewReportType.SdsSts].includes(currentInterview?.reportType!);

  const relativeStsEventQuestion = findRelativeStsEventQuestion(currentSequenceItems);

  const parentQuestions = getParentQuestions(currentSequenceItems);

  const formulaQuestionName = useMemo(() => {
    if (
      currentInterview?.reportType === InterviewReportType.SdsSts &&
      currentSequenceItems?.length
    ) {
      const questionId = currentSequenceItems.find(
        (item) => item.question?.questionAnswerType === QuestionAnswerType.Formula
      )?.questionId;
      return questionId && getQuestionControlName(questionId);
    }
  }, [currentSequenceItems, currentInterview]);

  const allQuestions = useMemo(() => {
    return currentSequenceItems?.map((item) => ({
      id: item.questionId,
      question: item.question,
    }));
  }, [currentSequenceItems]);

  const { validationData, defaultValues } = buildDefaultValueAndValidationObject(
    t,
    currentSequenceItems
  );
  const validationSchema = yup.object(validationData).required();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleSubmissionResponse = (submissionResponse: InterviewSubmissionResponse) => {
    if (submissionResponse.success) {
      if (submissionResponse.completed) {
        if (!currentReportType) {
          setCurrentReportType(currentInterview?.reportType!);
        }
        dispatch(clearCurrentInterview());
        if (submissionResponse.nextInterviewResultId) {
          setListResultIds(submissionResponse.nextInterviewResultId.toString());
          setNextInterviewResultId(submissionResponse.nextInterviewResultId);
        } else if (submissionResponse.nextTrackerResultId) {
          setListResultIds(submissionResponse.nextTrackerResultId.toString());
          setNextTrackerResultId(submissionResponse.nextTrackerResultId);
        }
        setInterviewCompleted(true);
      } else {
        dispatch(
          updateCurrentInterview({
            interview: submissionResponse.interview,
            canNavigateBack: submissionResponse.canNavigateBack,
          })
        );
        window.scrollTo({
          top: interviewContainerRef?.current?.offsetTop || 0,
          behavior: 'smooth',
        });
      }
      setPageErrors([]);
    }
  };

  const handleErrorResponse = (errorResponse: QuestionError) => {
    const { questions } = errorResponse;
    if (questions) {
      dispatch(setErrorFromResponse({ errorQuestions: questions }));
    }
  };

  const onSubmit = async (data: any) => {
    const timeframeError = checkTimeframe(
      currentInterview,
      data,
      currentSequenceItems,
      timeframeInDays
    );

    if (timeframeError) {
      setPageErrors([timeframeError]);
      return;
    }

    const submitData = {
      ...convertSubmitAnswers(interviewResultId, data, currentSequenceItems),
      patientId,
    };
    try {
      let submissionResponse: InterviewSubmissionResponse = {};
      if (isClinicianInterview) {
        submissionResponse = await submitInterviewAnswers(submitData).unwrap();
      } else {
        submissionResponse = await submitInterviewAnswersByToken({
          ...submitData,
          accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
        } as InterviewSubmissionByTokenRequest).unwrap();

        // reset last actity timestamp & token
        dispatch(clearPatientMonitorData());
        dispatch(
          setPatientInterviewData({
            data: {
              ...patientInterviewData,
              lastActivityTs: Date.now(),
              accessToken:
                submissionResponse.nextPatientInterviewToken ||
                submissionResponse.nextPatientMonitorToken ||
                submissionResponse.patientInterviewToken ||
                '',
            },
          })
        );
      }

      // TODO: update to handle similar logic for YBOCS
      if (currentInterview.reportType === InterviewReportType.MINI) {
        const currentQuestionId = currentSequenceItems![0]?.questionId || 0;
        const controlName = getQuestionControlName(currentQuestionId);
        resetField(controlName, { defaultValue: defaultValues[controlName] });
        resetField(ClinicianNoteField, { defaultValue: '' });
      } else if (
        currentInterview.reportType === InterviewReportType.YBOCS ||
        currentInterview.reportType === InterviewReportType.CYBOCS
      ) {
        if (currentSequenceItems) {
          currentSequenceItems.forEach(({ questionId }: SequenceItemDto) => {
            const controlName = getQuestionControlName(questionId);
            resetField(controlName, { defaultValue: defaultValues[controlName] });
          });
        }
      }

      handleSubmissionResponse(submissionResponse);
    } catch (e) {
      const {
        data: { error },
      } = e as QuestionResponseError;

      const questionError = error as any as QuestionError;
      handleErrorResponse(questionError);
      setPageErrors(questionError.interview || questionError || []);
    }
  };

  const onReset = () => {
    // reset client side display data
    const updatedHiddenQuestions = { ...hiddenQuestions };
    Object.keys(updatedHiddenQuestions).forEach((key) => {
      updatedHiddenQuestions[key] = true;
    });
    setHiddenQuestions(updatedHiddenQuestions);

    // reset disabled questions
    const updatedDisableQuestions = { ...disableQuestions };
    Object.keys(updatedDisableQuestions).forEach((key) => {
      updatedDisableQuestions[key] = true;
    });
    setDisableQuestions(updatedDisableQuestions);

    // reset form
    if (currentSequenceItems) {
      currentSequenceItems.forEach(({ questionId }: SequenceItemDto) => {
        const controlName = getQuestionControlName(questionId);
        resetField(controlName, { defaultValue: '' });
        dispatch(setResetQuestion({ reset: true }));
      });
    }
    resetField(ClinicianNoteField, { defaultValue: '' });
    reset();
  };

  const navigateBack = async () => {
    let submissionResponse: InterviewSubmissionResponse;
    clearErrors();
    if (isClinicianInterview) {
      submissionResponse = await submitInterviewAnswers({
        interviewTestId: interviewResultId,
        action: InterviewAction.NavigateBack,
      }).unwrap();
    } else {
      submissionResponse = await submitInterviewAnswersByToken({
        action: InterviewAction.NavigateBack,
        accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
      } as InterviewSubmissionByTokenRequest).unwrap();

      // reset last actity timestamp & token
      dispatch(clearPatientMonitorData());
      dispatch(
        setPatientInterviewData({
          data: {
            ...patientInterviewData,
            lastActivityTs: Date.now(),
            accessToken:
              submissionResponse.nextPatientInterviewToken ||
              submissionResponse.nextPatientMonitorToken ||
              submissionResponse.patientInterviewToken ||
              '',
          },
        })
      );
    }

    dispatch(setErrorFromResponse({ errorQuestions: [] }));

    // TODO: update to handle similar logic for YBOCS
    if (currentInterview.reportType === InterviewReportType.MINI) {
      const currentQuestionId = currentSequenceItems![0]?.questionId || 0;
      const controlName = getQuestionControlName(currentQuestionId);
      resetField(controlName, { defaultValue: defaultValues[controlName] });
      resetField(ClinicianNoteField, { defaultValue: '' });
    }

    handleSubmissionResponse(submissionResponse);
  };

  // Request interview from server
  useEffect(() => {
    if (shouldRequestInterviewRef.current) {
      const bindInterviewData = async () => {
        let interviewResponse: InterviewResponse | null = null;
        try {
          if (isClinicianInterview) {
            interviewResponse = await requestInterview({
              interviewTestId: interviewResultId,
            }).unwrap();
          } else if (patientInterviewData || patientMonitorData) {
            interviewResponse = await requestInterviewByToken({
              accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
            }).unwrap();
          }
          shouldRequestInterviewRef.current = false;

          if (interviewResponse) {
            dispatch(setCurrentInterview({ interviewData: interviewResponse }));
          }
        } catch (e) {
          if ((e as ResponseError).status === HttpStatusCode.BadRequest) {
            setDataLoadError(true);
          }
        }
      };
      bindInterviewData();
    }
  }, [
    dispatch,
    requestInterview,
    requestInterviewByToken,
    isClinicianInterview,
    interviewResultId,
    patientInterviewData,
    patientMonitorData,
  ]);

  // Bind initial question data
  useEffect(() => {
    // TODO: set value for Interview Edit case
    if (currentSequenceItems?.length) {
      if (previousAnswers?.length) {
        const setFormValue = (
          controlId: string,
          questionAnswerType: string,
          previousAnswerValue: InterviewAnswerValueDto
        ) => {
          const customValueGetterMap = {
            [QuestionAnswerType.Height]: () => ({
              valueInches: previousAnswerValue.valueInches,
              valueFeet: previousAnswerValue.valueFeet,
              valueCentimeters: previousAnswerValue.valueCentimeters,
            }),
            [QuestionAnswerType.Weight]: () => ({
              valuePounds: previousAnswerValue.valuePounds,
              valueKilograms: previousAnswerValue.valueKilograms,
            }),
          };
          const getPreviousAnswerValue =
            customValueGetterMap[questionAnswerType] || (() => previousAnswerValue.value);
          setValue(controlId, getPreviousAnswerValue());
        };

        // set previous answers
        previousAnswers.forEach((previousAnswer) => {
          const sequenceItem = currentSequenceItems.find(
            (item) => item.questionId === previousAnswer.questionId
          );
          if (sequenceItem) {
            setFormValue(
              getQuestionControlName(sequenceItem.questionId),
              sequenceItem.question?.questionAnswerType,
              previousAnswer.answer
            );
          }
        });
      }

      // set previous notes
      if (previousClinicianNotes?.length) {
        setValue(ClinicianNoteField, previousClinicianNotes);
      }

      // Set hidden questions (for PHQ-9 interview)
      const hiddenItems: any = {};
      currentSequenceItems
        .filter((item) => {
          return (
            !!item.question.clientDisplayCondition &&
            !previousAnswers?.some((preAnswer) => preAnswer.questionId === item.questionId)
          );
        })
        .forEach((item) => {
          hiddenItems[item.questionId] = true;
        });
      setHiddenQuestions(hiddenItems);

      // Set disabled questions (for ybocsCheckList, ybocsOtherCheckList)
      const disabledItems: any = {};
      currentSequenceItems
        .filter((item) => !!item.question.relatedQuestionIdForAnswerValues)
        .forEach((item) => {
          const previousRelatedAnswer = previousAnswers?.find(
            (previousAnswer) =>
              previousAnswer.questionId.toString() ===
              item.question.relatedQuestionIdForAnswerValues?.toString()
          );
          disabledItems[item.questionId] = !previousRelatedAnswer?.answer?.value?.trim().length;
        });
      setDisableQuestions(disabledItems);
    }
  }, [currentSequenceItems, previousAnswers, previousClinicianNotes, setValue]);

  // Watching form changes for formula question calculation
  useEffect(() => {
    if (formulaQuestionName) {
      const subscription = watch((_value, { name }) => {
        // only start watching when interview data are ready
        if (!shouldRequestInterviewRef.current) {
          if (name && name !== formulaQuestionName) {
            setValue(formulaQuestionName, { name });
          }
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, getValues, setValue, formulaQuestionName]);

  if (isInterviewCompleted) {
    let nextPageUrl = null;
    if (nextInterviewResultId) {
      nextPageUrl = patientId
        ? `/current-interview/continue-next-interview/${currentReportType}/${patientId}/${nextInterviewResultId}`
        : `/current-interview/continue-next-interview/${currentReportType}/${nextInterviewResultId}`;
    } else if (nextTrackerResultId) {
      nextPageUrl = patientId
        ? `/current-monitor/continue-next-monitor/${patientId}/${nextTrackerResultId}`
        : `/current-monitor/continue-next-monitor/${nextTrackerResultId}`;
    } else {
      nextPageUrl =
        isClinicianInterview || isEditInterview
          ? `/dashboard/current-interview/interview-complete/${patientId}/${interviewResultId}`
          : `/current-interview/complete/${interviewResultId}`;
    }

    return <Navigate to={nextPageUrl} state={{ editInterview: isEditInterview }}></Navigate>;
  }

  const savedListResultIds = localStorage.getItem('listResultIds');
  const parsedListResultIds = savedListResultIds ? JSON.parse(savedListResultIds) : [];
  const isValidResultId =
    !parsedListResultIds || parsedListResultIds.includes(inputInterviewResultId);

  if (
    !isValidInterviewConductRoute(reportTypePath!) ||
    dataLoadError ||
    (!isAuthenticated &&
      (!isValidResultId ||
        (!patientInterviewData?.accessToken && !patientMonitorData?.accessToken)))
  ) {
    return <ErrorPage statusCode={HttpStatusCode.NotFound} />;
  }

  if (
    !isClinicianInterview &&
    patientInterviewData &&
    (!patientInterviewData.lastActivityTs ||
      patientInterviewData.lastActivityTs + PATIENT_ASSESSMENT_EXPIRE_TIME_MILLISECONDS <
        Date.now())
  ) {
    dispatch(clearPatientInterviewData());
    dispatch(clearPatientMonitorData());
    localStorage.removeItem('listResultIds');
    navigate('/login');
    return <></>;
  }

  if (patientGetError) {
    return <ErrorPage statusCode={(patientGetError as FetchBaseQueryError).status} />;
  }

  const showQuestionAndModule =
    isClinicianInterview && currentInterview?.reportType === InterviewReportType.MINI;
  const isMultiPageInterview = [
    InterviewReportType.MINI,
    InterviewReportType.YBOCS,
    InterviewReportType.CYBOCS,
  ].includes(currentInterview?.reportType!);

  const showSummaryError = [
    InterviewReportType.Screener,
    InterviewReportType.YBOCS,
    InterviewReportType.CYBOCS,
    InterviewReportType.SdsSts,
  ].includes(currentInterview?.reportType!);

  // Exclude MINI, displays reset link on others
  const allowResetForm = [
    InterviewReportType.Screener,
    InterviewReportType.YBOCS,
    InterviewReportType.CYBOCS,
    InterviewReportType.SdsSts,
  ].includes(currentInterview?.reportType!);

  const relatedClientSideScripts = getRelatedClientSideScripts(currentSequenceItems || []);
  const relatedQuestions = getRelatedQuestions(currentSequenceItems || []);

  const optionChangeCallback = async () => {
    let newResult = await Promise.all(
      relatedClientSideScripts.map(async (clientSideItem) => {
        // eslint-disable-next-line no-new-func
        const evaluateFunction = new Function(clientSideItem.script);
        const shouldShowQuestion = await evaluateFunction();
        if (!shouldShowQuestion) {
          resetField(`question_${clientSideItem.questionId}`);
        }
        return { [clientSideItem.questionId]: !shouldShowQuestion };
      })
    );
    newResult = Object.assign({}, ...newResult);
    setHiddenQuestions(newResult);
  };

  const ybocsCheckListChangeCallback = (relatedQuestionId?: string, disabled?: boolean) => {
    relatedQuestions
      .filter((x) => x?.relatedQuestionId?.toString() === relatedQuestionId)
      .forEach((relatedQuestion) => {
        setDisableQuestions({
          ...disableQuestions,
          [relatedQuestion.questionId]: disabled,
        });
        disabled &&
          resetField(getQuestionControlName(relatedQuestion.questionId), { defaultValue: '' });
      });
  };

  const renderInterviewQuestion = (item: SequenceItemDto) => {
    const controlName = getQuestionControlName(item.questionId);
    const error = errors[controlName];
    const responseError = errorAnswerMessages && errorAnswerMessages[item.questionId];
    const shouldPassAllQuestions =
      currentInterview?.reportType === InterviewReportType.SdsSts &&
      item.question.questionAnswerType === QuestionAnswerType.Formula;

    return (
      <Controller
        key={controlName}
        name={controlName}
        control={control}
        defaultValue={defaultValues[controlName] || ''}
        render={({ field }) => (
          <InterviewQuestion
            questionId={item.questionId}
            question={item.question}
            error={!!error || !!responseError}
            helperText={error?.message || responseError}
            field={field}
            setValue={setValue}
            getValues={getValues}
            answerUnit={answerUnit}
            optionChangeCallback={
              relatedClientSideScripts.length ? optionChangeCallback : undefined
            }
            stsQuestionRelative={relativeStsEventQuestion}
            disabled={disableQuestions[item.questionId]}
            isParentQuestion={parentQuestions.indexOf(item.questionId) >= 0}
            checkListChangeCallback={
              relatedQuestions.length ? ybocsCheckListChangeCallback : undefined
            }
            allQuestions={shouldPassAllQuestions ? allQuestions : undefined}
            isStsSdsQuestion={showSdsStdModule}
            sequenceOrder={item.sequenceOrder}
          />
        )}
      />
    );
  };

  const renderModuleInfo = (item: SequenceItemDto, showQuestionAndModule: boolean) => {
    const StyledBox = styled(Box)({
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8px',
      color: theme.xPalette.disabledText,
    });

    return (
      showQuestionAndModule && (
        <>
          <Divider sx={{ my: 2 }} />
          <StyledBox>
            <Typography fontWeight="bold">{t('interview.form.moduleLabel')}</Typography>
            <Typography>{currentModule.name}</Typography>
          </StyledBox>
          <StyledBox>
            <Typography fontWeight="bold">{t('interview.form.questionLabel')}</Typography>
            <Typography>{item.question.name}</Typography>
          </StyledBox>
        </>
      )
    );
  };

  const renderResetLink = () => {
    return (
      allowResetForm && (
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'center',
            [theme.breakpoints.up('md')]: {
              marginRight: 'auto',
              width: 1,
            },
          }}
        >
          <Link href="#" onClick={onReset}>
            {t('interview.form.resetLink')}
          </Link>
        </Box>
      )
    );
  };

  return (
    <>
      {isClinicianInterview && (
        <RoundedContainer sx={{ py: 2 }}>
          <PatientInfoCard data={patientInfo!} interview={currentInterview} />
        </RoundedContainer>
      )}

      {!currentInterview ? (
        <Loading />
      ) : (
        <Box sx={{ mt: 2 }} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          {/* MINI / Screener / SdsSts */}
          {(showMiniScreenerModule || showSdsStdModule) && (
            <>
              {currentSequenceItems &&
                currentSequenceItems
                  .filter((item) => !hiddenQuestions[item.questionId])
                  .map((item: SequenceItemDto) => {
                    return (
                      <RoundedContainer key={item.questionId} sx={{ py: 2, mb: 2 }}>
                        {renderInterviewQuestion(item)}
                        {renderModuleInfo(item, showQuestionAndModule)}
                      </RoundedContainer>
                    );
                  })}
            </>
          )}
          {/* YBOCS/CYBOCS checklist */}
          {showYbocsModule && isYbocsCheckList && (
            <Box sx={{ mb: 2 }}>
              <StyledHeaderWrapper>
                <StyledQuestionNameHeader />
                <StyledCheckListAnswerBox>
                  <StyledAnswerHeader>{t('interview.form.ybocs.current')}</StyledAnswerHeader>
                </StyledCheckListAnswerBox>
                <StyledCheckListAnswerBox>
                  <StyledAnswerHeader>{t('interview.form.ybocs.past')}</StyledAnswerHeader>
                </StyledCheckListAnswerBox>
                <StyledCheckListAnswerBox>
                  <StyledAnswerHeader>{t('interview.form.ybocs.principal')}</StyledAnswerHeader>
                </StyledCheckListAnswerBox>
              </StyledHeaderWrapper>
              {currentSequenceItems &&
                currentSequenceItems
                  .filter(
                    (item) =>
                      !hiddenQuestions[item.questionId] &&
                      item.question.questionAnswerType !== QuestionAnswerType.InfoNoAnswer
                  )
                  .map((item: SequenceItemDto) => renderInterviewQuestion(item))}
            </Box>
          )}
          {/* YBOCS/CYBOCS text & scale*/}
          {showYbocsModule && (isYbocsTargetSymptomListText || isYbocsScale) && (
            <>
              {currentSequenceItems &&
                currentSequenceItems
                  .filter(
                    (item) =>
                      !hiddenQuestions[item.questionId] &&
                      item.question.questionAnswerType !== QuestionAnswerType.TargetSymptomListText
                  )
                  .map((item: SequenceItemDto) => (
                    <RoundedContainer key={item.questionId} sx={{ py: 2, mb: 2 }}>
                      {renderInterviewQuestion(item)}
                      {renderModuleInfo(item, showQuestionAndModule)}
                    </RoundedContainer>
                  ))}
            </>
          )}
          {previousClinicianNoteVersions && previousClinicianNoteVersions.length !== 0 && (
            <RoundedContainer sx={{ py: 2, mb: 2 }}>
              <Typography mb={1} fontWeight="bold">
                {t('interview.clinicianNoteVersions')}
              </Typography>
              {previousClinicianNoteVersions.map((noteInfo: ClinicianNoteVersionsType) => (
                <Box
                  key={noteInfo.version}
                  sx={{
                    p: 2,
                    mb: 2,
                    backgroundColor: theme.xPalette.background,
                    borderRadius: 2,
                  }}
                >
                  {`Version ${noteInfo.version} - ${
                    noteInfo.interviewerName
                      ? noteInfo.interviewerName
                      : `${noteInfo.lastName}, ${noteInfo.firstName}`
                  } wrote:`}
                  <br />
                  <Typography sx={{ ml: 2, mt: 2 }}>{noteInfo.noteContent}</Typography>
                </Box>
              ))}
            </RoundedContainer>
          )}
          <RoundedContainer
            sx={{
              py: 2,
              mb: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {!showYbocsModule && isClinicianInterview && (
              <>
                <Typography>{t('interview.form.clinicianNotes')}</Typography>
                <Controller
                  name={ClinicianNoteField}
                  control={control}
                  render={({ field }) => (
                    <StyledClinicianNote variant="outlined" {...field} multiline maxRows={4} />
                  )}
                />
              </>
            )}

            {pageErrors.length > 0 &&
              pageErrors.map((error) => (
                <Alert key={error} severity="error" sx={{ my: 2 }}>
                  {error}
                </Alert>
              ))}
            {isMultiPageInterview ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 1,
                }}
              >
                {showSummaryError && (
                  <InterviewSummaryError errors={errors}></InterviewSummaryError>
                )}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 2,
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      columnGap: 1,
                    },
                  }}
                >
                  {renderResetLink()}
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: 'row',
                      columnGap: 1,
                      [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        columnGap: 1,
                      },
                    }}
                  >
                    {canNavigateBack && (
                      <StyledButton variant="outlined" onClick={navigateBack}>
                        {t('interview.form.previous')}
                      </StyledButton>
                    )}
                    <StyledButton type="submit" variant="contained">
                      {t('interview.form.next')}
                    </StyledButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                {showSummaryError && (
                  <InterviewSummaryError errors={errors}></InterviewSummaryError>
                )}
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 2,
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                      columnGap: 1,
                    },
                  }}
                >
                  {renderResetLink()}
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: 'row',
                      columnGap: 1,
                      [theme.breakpoints.up('md')]: {
                        flexDirection: 'row',
                        columnGap: 1,
                      },
                    }}
                  >
                    <StyledButton
                      sx={{
                        my: 1,
                        width: '100%',
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t('interview.form.submit')}
                    </StyledButton>
                  </Box>
                </Box>
              </>
            )}
          </RoundedContainer>
        </Box>
      )}
    </>
  );
}
