import { t } from 'i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../app/hooks';
import { InterviewMode } from '../../../common/utils/interviewsMode';
import {
  QuestionAnswerType,
  QuestionAnswerTypeMINIMinimize,
  QuestionComponentsMap,
} from '../../../common/utils/questionAnswerTypes';
import { InterviewQuestionProps } from '../../../types/InterviewQuestionTypes';
import { selectInterviewMode } from '../interviewSlice';
import MinimizeBinaryQuestion from './MiniMinimizeInterview/questions/MinimizeBinaryQuestion';
import MinimizeMultiOptionsQuestion from './MiniMinimizeInterview/questions/MinimizeMultiOptionsQuestion';
import MinimizeNumberQuestion from './MiniMinimizeInterview/questions/MinimizeNumberQuestion';
import MinimizeOptionsQuestion from './MiniMinimizeInterview/questions/MinimizeOptionsQuestion';
import MinimizeTextQuestion from './MiniMinimizeInterview/questions/MinimizeTextQuestion';

const MiniMinimizeQuestionComponentsMap = {
  ...QuestionComponentsMap,
  [QuestionAnswerType.MultiOptions]: MinimizeMultiOptionsQuestion,
  [QuestionAnswerType.Text]: MinimizeTextQuestion,
  [QuestionAnswerType.Number]: MinimizeNumberQuestion,
  [QuestionAnswerType.Binary]: MinimizeBinaryQuestion,
  [QuestionAnswerTypeMINIMinimize.MINIMinimizeOptions]: MinimizeOptionsQuestion,
};

export default function ExtendedInterviewQuestion({
  question,
  questionId,
  isFromGroup,
  ...rest
}: InterviewQuestionProps) {
  const interviewMode = useAppSelector(selectInterviewMode);
  const isPatientInterview = interviewMode === InterviewMode.Patient;
  const showWarningText = !isPatientInterview && question.isClinicianOnly;
  const showHelpText =
    !isPatientInterview && !question.isPatientOnly && !!question.clinicianHelpText;

  const QuestionComponent =
    isFromGroup && question.questionAnswerType === QuestionAnswerType.Options
      ? MiniMinimizeQuestionComponentsMap[QuestionAnswerTypeMINIMinimize.MINIMinimizeOptions]
      : MiniMinimizeQuestionComponentsMap[question.questionAnswerType];
  if (!QuestionComponent || !question) {
    return (
      <Typography color="red">
        {/* // TODO: Remove warning message once done implenentation */}
        *** Type {question.questionAnswerType} is not implement yet!
      </Typography>
    );
  }

  return (
    <Box width="100%">
      {showWarningText && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('interview.form.clinicianOnlyWarning'),
            }}
          ></Typography>
        </Alert>
      )}
      <QuestionComponent
        question={question}
        questionId={questionId}
        isFromGroup={isFromGroup}
        {...rest}
      ></QuestionComponent>

      {question.questionHelpText && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography
            dangerouslySetInnerHTML={{
              __html: question.questionHelpText,
            }}
          ></Typography>
        </>
      )}

      {showHelpText && (
        <Alert severity="info">
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('interview.form.clinicianHelpText'),
            }}
          ></Typography>
          <Typography
            sx={{ wordBreak: 'break-word' }}
            dangerouslySetInnerHTML={{
              __html: question.clinicianHelpText!,
            }}
          ></Typography>
        </Alert>
      )}
    </Box>
  );
}
