import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppSelector } from '../../../../../app/hooks';
import theme from '../../../../../app/theme';
import RoundedContainer from '../../../../../common/components/RoundedContainer';
import { InterviewMode } from '../../../../../common/utils/interviewsMode';
import { SequenceItemDto } from '../../../../../types/InterviewQuestionTypes';
import ClinicianNotesLink from '../../../../clinician-notes/ClinicianNotesLink';
import { selectInterviewMode } from '../../../interviewSlice';

export default function OptionGroup({
  item,
  renderInterviewQuestion,
}: {
  item: SequenceItemDto[];
  renderInterviewQuestion: any;
}) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const interviewMode = useAppSelector(selectInterviewMode);
  const isPatientInterview = interviewMode === InterviewMode.Patient;
  const showHelpText =
    !isPatientInterview && !item[0].question.isPatientOnly && !!item[0].question.clinicianHelpText;

  const { pathname } = useLocation();
  const { interviewResultId } = useParams();

  const isEditInterview = pathname?.includes('edit-interview');
  const isClinicianInterview = interviewMode === InterviewMode.Clinician || isEditInterview;
  const questionIds = item.map((question) => question.questionId);

  const groupQuestionUI = item.map((sequenceItem: SequenceItemDto, index: number) => {
    return (
      <>
        {renderInterviewQuestion(sequenceItem, true)}
        {index < item.length - 1 && (
          <Divider
            sx={{
              width: '1px',
              ml: 2,
              mr: 2,
              backgroundColor: theme.xPalette.lightGrey,
            }}
          />
        )}
      </>
    );
  });

  const renderPossibleAnswers = (item: SequenceItemDto) => {
    const result = [];
    for (let index = 0; index < item.question.displayAnswers.length; index++) {
      result.push(
        <Typography>
          {`${item.question.displayAnswers[index]} ${item.question.possibleAnswers[index]}`}
        </Typography>
      );
    }
    return result;
  };

  return (
    <RoundedContainer
      sx={{
        py: 2,
        mb: 2,
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box width={isDesktop ? '54%' : '100%'}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: item[0].question.questionText!,
          }}
        ></Typography>
        <Box ml="30px">{renderPossibleAnswers(item[0])}</Box>
        {showHelpText && (
          <Alert severity="info">
            <Typography
              dangerouslySetInnerHTML={{
                __html: t('interview.form.clinicianHelpText'),
              }}
            ></Typography>
            <Typography
              sx={{ wordBreak: 'break-word' }}
              dangerouslySetInnerHTML={{
                __html: item[0].question.clinicianHelpText!,
              }}
            ></Typography>
          </Alert>
        )}
        {isClinicianInterview && (
          <ClinicianNotesLink
            interviewTestId={parseInt(interviewResultId!)}
            questionIds={questionIds}
          ></ClinicianNotesLink>
        )}
      </Box>

      <Box
        sx={{ width: isDesktop ? '44%' : '100%', display: 'flex', marginTop: isDesktop ? 0 : 1 }}
      >
        {groupQuestionUI}
      </Box>
    </RoundedContainer>
  );
}
