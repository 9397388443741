import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { WatchedQuestion } from '../../../types/InterviewRequest';
import {
  selectIsEditInterview,
  selectIsInProgressModule,
  setWatchQuestion,
} from '../interviewSlice';

interface Props extends WatchedQuestion {}

const useSetWatchQuestion = ({ name, type, required }: Props) => {
  const dispatch = useAppDispatch();
  const isInProgressModule = useAppSelector<boolean>(selectIsInProgressModule);
  const interviewEdited = useAppSelector<boolean>(selectIsEditInterview);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const handleOnBlur = (time?: number): void => {
    const debounceTime = time || 300;
    if (isInProgressModule && name && !interviewEdited) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        dispatch(setWatchQuestion({ name, type, required }));
      }, debounceTime);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return { handleOnBlur };
};

export default useSetWatchQuestion;
