import { JSXElementConstructor } from 'react';
import BinaryQuestion from '../../features/interview/questions/BinaryQuestion';
import DateQuestion from '../../features/interview/questions/DateQuestion';
import ElectronicSignatureQuestion from '../../features/interview/questions/ElectronicSignatureQuestion';
import FormularQuestion from '../../features/interview/questions/FormularQuestion';
import GridQuestion from '../../features/interview/questions/GridQuestion';
import HeightQuestion from '../../features/interview/questions/HeightQuestion';
import InfoNoAnswer from '../../features/interview/questions/InfoNoAnswer';
import MultiOptionsQuestion from '../../features/interview/questions/MultiOptionsQuestion';
import MultiSelectQuestion from '../../features/interview/questions/MultiSelectQuestion';
import NumberQuestion from '../../features/interview/questions/NumberQuestion';
import OptionsQuestion from '../../features/interview/questions/OptionsQuestion';
import SdsScaleQuestion from '../../features/interview/questions/SdsScaleQuestion';
import StsEventQuestion from '../../features/interview/questions/StsEventQuestion';
import StsScaleQuestion from '../../features/interview/questions/StsScaleQuestion';
import TableQuestion from '../../features/interview/questions/TableQuestion';
import TextQuestion from '../../features/interview/questions/TextQuestion';
import TimeDayMonthQuestion from '../../features/interview/questions/TimeDayMonthQuestion';
import TimeQuestion from '../../features/interview/questions/TimeQuestion';
import WeightQuestion from '../../features/interview/questions/WeightQuestion';
import YbocsCheckListQuestion from '../../features/interview/questions/YbocsCheckListQuestion';
import YbocsCheckOtherQuestion from '../../features/interview/questions/YbocsCheckOtherQuestion';
import YbocsScaleQuestion from '../../features/interview/questions/YbocsScaleQuestion';
import { InterviewQuestionProps } from '../../types/InterviewQuestionTypes';

export const QuestionAnswerType = {
  Binary: 'binary',
  Formula: 'formula',
  Grid: 'grid',
  Height: 'height',
  InfoNoAnswer: 'infoNoAnswer',
  MultiOptions: 'multiOptions',
  MultiSelectList: 'multiSelectList',
  Number: 'number',
  Options: 'options',
  SdsScale: 'sdsScale',
  StsEvent: 'stsEvent',
  StsScale: 'stsScale',
  Table: 'table',
  TargetSymptomListText: 'targetSymptomListText',
  Text: 'text',
  Time: 'time',
  TimeDayMonth: 'time (Day/Month)',
  Weight: 'weight',
  YbocsCheckList: 'ybocsCheckList',
  YbocsCheckOther: 'ybocsCheckOther',
  YbocsExitCondition: 'ybocsExitCondition',
  YbocsScale: 'ybocsScale',
  Ybocs: 'ybocs',
  Cybocs: 'cybocs',
  Questionnaire: 'questionnaire',
  InfoNoAnswerNoUI: 'infoNoAnswerNoUI',
  MonitorFormula: 'monitorFormula',
  PHQFormula: 'PHQFormula',
  ElectronicSignature: 'eSignature',
  Date: 'date',
};

export const QuestionAnswerTypeMINIMinimize = {
  MINIMinimizeOptions: 'MINIMinimizeOptions',
  MINIMinimizeBinary: 'MINIMinimizeBinary',
};

export const GroupQuestionType = {
  VerticalGroup: 'verticalGroup',
  HorizontalGroup: 'horizontalGroup',
};

export type AnswerUnit = 'imperial' | 'metrics';

export const QuestionComponentsMap: {
  [type: string]: JSXElementConstructor<InterviewQuestionProps>;
} = {
  [QuestionAnswerType.Binary]: BinaryQuestion,
  [QuestionAnswerType.Height]: HeightQuestion,
  [QuestionAnswerType.Weight]: WeightQuestion,
  [QuestionAnswerType.Text]: TextQuestion,
  [QuestionAnswerType.Options]: OptionsQuestion,
  [QuestionAnswerType.MultiSelectList]: MultiSelectQuestion,
  [QuestionAnswerType.TimeDayMonth]: TimeDayMonthQuestion,
  [QuestionAnswerType.Time]: TimeQuestion,
  [QuestionAnswerType.Number]: NumberQuestion,
  [QuestionAnswerType.MultiOptions]: MultiOptionsQuestion,
  [QuestionAnswerType.YbocsCheckList]: YbocsCheckListQuestion,
  [QuestionAnswerType.YbocsCheckOther]: YbocsCheckOtherQuestion,
  [QuestionAnswerType.YbocsScale]: YbocsScaleQuestion,
  [QuestionAnswerType.SdsScale]: SdsScaleQuestion,
  [QuestionAnswerType.StsScale]: StsScaleQuestion,
  [QuestionAnswerType.Grid]: GridQuestion,
  [QuestionAnswerType.Table]: TableQuestion,
  [QuestionAnswerType.StsEvent]: StsEventQuestion,
  [QuestionAnswerType.InfoNoAnswer]: InfoNoAnswer,
  [QuestionAnswerType.Formula]: FormularQuestion,
  [QuestionAnswerType.ElectronicSignature]: ElectronicSignatureQuestion,
  [QuestionAnswerType.Date]: DateQuestion,
};
