import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import LocalPopupModal from '../../common/components/LocalPopupModal';
import { PopupContent } from '../../types/PopupType';
import { ClinicianNotesRequestDto } from '../../types/clinician-notes/ClinicianNotes';
import ClinicianNotesForm from './ClinicianNotesForm';

interface ClinicianNotesFormProps {
  interviewTestId: number;
  questionIds?: number[];
}

export default function ClinicianNotesLink({
  interviewTestId,
  questionIds,
}: ClinicianNotesFormProps) {
  const { t } = useTranslation();
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<ClinicianNotesRequestDto | null>(null);

  const showClinicianNotesPopup = async (clinicianNotesData: ClinicianNotesRequestDto) => {
    setIsOpenEditPopup(true);
    setEditingData(clinicianNotesData);
  };

  const editContentPopup = {
    title: t('interview.form.clinicianNotes'),
  } as PopupContent;

  const closeEditPopup = async () => {
    setIsOpenEditPopup(false);
  };

  const onFormSubmitCallback = async (result: boolean) => {
    if (result) {
      setIsOpenEditPopup(false);
    }
  };

  return (
    <>
      <Link
        textAlign="left"
        underline="always"
        component="button"
        variant="subtitle1"
        onClick={(event) => {
          event.preventDefault();
          showClinicianNotesPopup({ interviewTestId, questionIds });
        }}
        sx={{
          textDecoration: 'underline',
        }}
      >
        {t('interview.form.clinicianNotes')}
      </Link>
      <LocalPopupModal
        isOpen={isOpenEditPopup}
        contentPopup={editContentPopup}
        onClose={closeEditPopup}
        maxWidth={'md'}
        fullWidth={true}
      >
        {editingData && (
          <ClinicianNotesForm
            interviewTestId={editingData.interviewTestId}
            questionIds={editingData.questionIds}
            onFormSubmitCallback={onFormSubmitCallback!}
          />
        )}
      </LocalPopupModal>
    </>
  );
}
