import { useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { InterviewAnswerDto, SequenceItemDto } from '../../../types/InterviewQuestionTypes';
import { InterviewSubmissionRequest, WatchedQuestion } from '../../../types/InterviewRequest';
import { setWatchQuestion } from '../interviewSlice';
import { ClinicianNoteField, getQuestionControlName } from '../interviewUtils';

//TODO: Move this to common file, double check other places using "convertSubmitAnswers"
const convertSubmitAnswers = (
  interviewResultId: number,
  submitAnswers: Record<string, any>,
  currentSequenceItems?: SequenceItemDto[]
): InterviewSubmissionRequest => {
  const answers: InterviewAnswerDto[] = [];

  currentSequenceItems?.forEach((item) => {
    const controlName = getQuestionControlName(item.questionId);
    const submitAnswer = submitAnswers[controlName];

    if (submitAnswer !== undefined && submitAnswer !== null) {
      const answer: InterviewAnswerDto = {
        questionId: item.questionId,
        answer: typeof submitAnswer === 'object' ? { ...submitAnswer } : { value: submitAnswer },
      };

      answers.push(answer);
    }
  });

  return {
    interviewTestId: interviewResultId,
    clinicianNotes: submitAnswers[ClinicianNoteField],
    answers,
  };
};

interface Props {
  interviewResultId: number;
  currentSequenceItems: any[];
  patientId?: string;
  isClinicianInterview: boolean;
  patientInterviewData?: { accessToken?: string };
  patientMonitorData?: { accessToken?: string };
  submitInterviewAnswers: (data: any) => any;
  submitInterviewAnswersByToken: (data: any) => any;
  isInProgressModule?: boolean;
  control: any;
  watchQuestion: WatchedQuestion;
  interviewEdited: boolean;
}

const useSubmitSingleQuestion = ({
  isInProgressModule,
  interviewResultId,
  currentSequenceItems,
  patientId,
  isClinicianInterview,
  patientInterviewData,
  patientMonitorData,
  submitInterviewAnswers,
  submitInterviewAnswersByToken,
  control,
  watchQuestion,
  interviewEdited,
}: Props): {
  queueStillRunning: boolean;
  addRequestToSubQueue: (callBack: any) => void;
} => {
  const dispatch = useAppDispatch();
  const previousAnswer = useRef<Partial<WatchedQuestion> & { answer: any }>({
    name: '',
    type: '',
    answer: '',
  });
  const watchedFieldAnswer = control._getWatch(watchQuestion?.name);
  const requestQueue = useRef<(() => Promise<any>)[]>([]);
  const isProcessing = useRef<boolean>(false);

  const processQueue = async (submitSingle: boolean = true) => {
    if (!isProcessing.current || !requestQueue.current.length) {
      isProcessing.current = true;
      while (requestQueue.current.length > 0) {
        try {
          const currentRequest = requestQueue.current[0];
          await currentRequest();

          if (!submitSingle) {
            requestQueue.current = [];
            isProcessing.current = false;
          }
          requestQueue.current.shift();
        } catch (e) {
          requestQueue.current = [];
          isProcessing.current = false;
        }
      }

      previousAnswer.current = { ...watchQuestion, answer: watchedFieldAnswer };
      isProcessing.current = false;
      dispatch(setWatchQuestion({ name: '', type: '', required: false }));
    }
  };

  const createSubmitData = () => {
    return {
      ...convertSubmitAnswers(
        interviewResultId,
        { [watchQuestion.name]: watchedFieldAnswer },
        currentSequenceItems
      ),
      patientId,
      action: 'singleAnswer',
      notLoading: true,
    };
  };

  const enqueueRequest = async (submitData: any) => {
    try {
      if (isClinicianInterview) {
        await submitInterviewAnswers(submitData).unwrap();
      } else {
        await submitInterviewAnswersByToken({
          ...submitData,
          accessToken: patientInterviewData?.accessToken || patientMonitorData?.accessToken,
        }).unwrap();
      }
    } catch (e) {
      throw e;
    }
  };

  const addRequestToSubQueue = (callBack: any) => {
    requestQueue.current.push(() => callBack());
    processQueue(false);
  };

  useEffect(() => {
    if (interviewEdited || !watchQuestion || (!watchedFieldAnswer && watchQuestion.required)) {
      return;
    }

    if (isInProgressModule) {
      if (
        previousAnswer.current.name !== watchQuestion.name ||
        watchedFieldAnswer !== previousAnswer.current.answer
      ) {
        const submitData = createSubmitData();

        if (submitData.answers?.length) {
          requestQueue.current.push(() => enqueueRequest(submitData));
          processQueue();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchQuestion]);

  return {
    queueStillRunning: isProcessing.current,
    addRequestToSubQueue,
  };
};

export default useSubmitSingleQuestion;
