import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../../../app/hooks';
import theme from '../../../../../app/theme';
import RoundedContainer from '../../../../../common/components/RoundedContainer';
import { InterviewMode } from '../../../../../common/utils/interviewsMode';
import { SequenceItemDto } from '../../../../../types/InterviewQuestionTypes';
import ClinicianNotesLink from '../../../../clinician-notes/ClinicianNotesLink';
import { selectInterviewMode } from '../../../interviewSlice';

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  marginBottom: '8px',
}));

interface BinaryGroupProps {
  item: SequenceItemDto[];
  renderInterviewQuestion: (item: SequenceItemDto, isFromGroup?: boolean) => JSX.Element;
}

export default function MultiOptionsGroup({ item, renderInterviewQuestion }: BinaryGroupProps) {
  const { pathname } = useLocation();
  const { interviewResultId } = useParams();
  const isEditInterview = pathname?.includes('edit-interview');
  const interviewMode = useAppSelector(selectInterviewMode);
  const questionIds = item.map((question) => question.questionId);
  const isClinicianInterview = interviewMode === InterviewMode.Clinician || isEditInterview;
  const groupQuestionUI = item.map((sequenceItem: SequenceItemDto, index: number) => {
    return (
      <Box>
        {renderInterviewQuestion(sequenceItem)}
        {index < item.length - 1 && (
          <Divider
            sx={{
              width: '1px',
              ml: 2,
              mr: 2,
              backgroundColor: theme.xPalette.lightGrey,
            }}
          />
        )}
      </Box>
    );
  });

  return (
    <Box key={`group-${item[0].questionId}`}>
      <RoundedContainer
        sx={{
          py: 2,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {groupQuestionUI}
        {isClinicianInterview && (
          <ClinicianNotesLink
            interviewTestId={parseInt(interviewResultId!)}
            questionIds={questionIds}
          ></ClinicianNotesLink>
        )}
      </RoundedContainer>
    </Box>
  );
}
