import { MasterReportType, ReportType } from './assessmentType';

export const reportTypes = [
  { id: ReportType.Interview, name: 'Interview' },
  { id: ReportType.Monitor, name: 'Monitor' },
];

export const masterReportTypes = [
  { id: MasterReportType.All, name: 'All' },
  { id: MasterReportType.Interview, name: 'Interview' },
  { id: MasterReportType.Monitor, name: 'Monitor' },
];

export const LineItemScores = {
  Met: 'met',
  NotMet: 'notMet',
  Na: 'na',
  NeedClinicianReview: 'met* Scoring requires further clinician review',
};

export enum AnswerUnit {
  Imperial = 'imperial',
  Metrics = 'metrics',
}

export const DisorderScreener: { [key: string]: string } = {
  EATING_DISORDER_SCREENER: 'Eating Disorder Screener',
  SUBSTANCE_USE_DISORDER_SCREENER: 'Substance Use Disorder Screener',
  ANXIETY_DISORDER_SCREENER: 'Anxiety Disorder Screener',
  MOOD_DISORDER_SCREENER: 'Mood Disorder Screener',
};

export const DisorderScreenerInterview: { [key: string]: string } = {
  [DisorderScreener.MOOD_DISORDER_SCREENER]:
    'assessmentReport.screenerReport.disclaimerTextMoodDisorder',
  [DisorderScreener.ANXIETY_DISORDER_SCREENER]:
    'assessmentReport.screenerReport.disclaimerTextAnxietyDisorder',
  [DisorderScreener.SUBSTANCE_USE_DISORDER_SCREENER]:
    'assessmentReport.screenerReport.disclaimerTextSubstanceUseDisorder',
  [DisorderScreener.EATING_DISORDER_SCREENER]:
    'assessmentReport.screenerReport.disclaimerTextEatingDisorder',
};

export enum ItemType {
  ExitCondition = 'exitCondition',
  Question = 'question',
}
