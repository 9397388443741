import { t } from 'i18next';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { InterviewFormatDto, SequenceItemDto } from '../../types/InterviewQuestionTypes';
import { MeasurementPacket } from '../../types/InterviewRequest';
import { MeasurementPacketType } from '../../types/PatientType';

export const getQuestionControlName = (questionId: number | string) => `question_${questionId}`;

export const ClinicianNoteField = 'clinicianNote';

export const InterviewReportType = {
  MINI: 'mini',
  Screener: 'miniScreener',
  SdsSts: 'sdsSts',
  YBOCS: 'ybocs',
  CYBOCS: 'cybocs',
  PHQ: 'phq',
  Monitor: 'tracker',
};

export const InterviewAction = {
  NavigateBack: 'navigateBack',
};

export const StyledClinicianNote = styled(TextField)(({ theme, ...props }) => ({
  margin: '16px 0',
  backgroundColor: theme.xPalette.noteLightGrey,
  borderRadius: '12px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&:hover fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.xPalette.white,
    },
    '&.Mui-disabled fieldset': {
      borderColor: theme.xPalette.white,
    },
  },
}));

export const getFirstInterviewInPackets = (
  measurementPackets: MeasurementPacket[]
): MeasurementPacket | null => {
  if (measurementPackets?.length) {
    return (
      measurementPackets.find((measurementPacket) => Boolean(measurementPacket.interviewId)) || null
    );
  }
  return null;
};

export const getFirstMonitorInPackets = (
  measurementPackets: MeasurementPacket[]
): MeasurementPacket | null => {
  if (measurementPackets?.length) {
    return (
      measurementPackets.find((measurementPacket) => Boolean(measurementPacket.monitorId)) || null
    );
  }
  return null;
};

export const getRequestData = (
  measurementPackets: MeasurementPacket[]
): { interviewRequestData: any; monitorRequestData: any } => {
  let interviewRequestData = null;
  let monitorRequestData = null;

  const firstInterview = getFirstInterviewInPackets(measurementPackets);
  if (firstInterview) {
    interviewRequestData = {
      interviewId: firstInterview?.interviewId ? firstInterview.interviewId : 0,
      interviewTestId: null,
      interviewTimeFrame: firstInterview.interviewTimeFrame,
      language: firstInterview.language,
      trialInterview: firstInterview.trialInterview,
      endDate: firstInterview.endDate,
      providerId: firstInterview.providerId || 0,
      measurementPacketType: MeasurementPacketType.MeasurementPacket,
      measurementPackets,
    };
  } else {
    const firstMonitor = getFirstMonitorInPackets(measurementPackets);
    if (firstMonitor) {
      monitorRequestData = {
        monitorId: firstMonitor?.monitorId ? firstMonitor.monitorId : 0,
        language: firstMonitor.language,
        providerId: firstMonitor.providerId || 0,
        endDate: firstMonitor.endDate,
        measurementPacketType: MeasurementPacketType.MeasurementPacket,
        measurementPackets,
      };
    }
  }
  return { interviewRequestData, monitorRequestData };
};

export const addQuestionHead = (
  item: SequenceItemDto,
  isClinicianInterview: boolean,
  isNotGroup?: boolean
) => {
  const head = item.question.name?.split('_') ?? [];
  const question =
    (head[1] === 'current' || head[1] === 'past') && !isNotGroup ? head[0] : item.question.name;
  const groupQuestionName = isClinicianInterview ? `${question}.` : '';
  let questionText = item.question.questionText;
  let questionPrompt = item.question.questionPrompt;
  if (isNotGroup && questionPrompt) {
    questionPrompt = `<b>${groupQuestionName} </b>${item.question.questionPrompt}`;
  } else {
    questionText = `<b>${groupQuestionName} </b>${item.question.questionText}`;
  }
  const updatedItem = {
    ...item,
    question: {
      ...item.question,
      questionPrompt: questionPrompt,
      questionText: questionText,
    },
  };
  return updatedItem;
};

const checkEntries = (subObject: Record<string, any>, mainObject: Record<string, any>): boolean => {
  for (const key in subObject) {
    const valueA = subObject[key];
    const valueB = mainObject[key];

    if (!(key in mainObject)) {
      return false;
    }

    if (typeof valueA === 'object' && valueA !== null) {
      if (typeof valueB !== 'object' || valueB === null) {
        return false;
      }
      if (!checkEntries(valueA, valueB)) {
        return false;
      }
    } else {
      if (valueA !== valueB) {
        return false;
      }
    }
  }
  return true;
};

export const isNotChangeQuestion = (
  objectA: Record<string, any>,
  objectB: Record<string, any>
): boolean => {
  if (Object.keys(objectA).length === 0 && Object.keys(objectB).length === 0) {
    return true;
  }

  if (Object.keys(objectA).length === 0 || Object.keys(objectB).length === 0) {
    return false;
  }

  return checkEntries(objectA, objectB) || checkEntries(objectB, objectA);
};

export const convertObject = (input: Record<number, any>): Record<any, any> => {
  return Object.entries(input).reduce((acc, [key, object]) => {
    const value = object.value ?? object;
    if (value !== '' && value !== undefined) {
      acc[`question_${key}`] = value;
    }
    return acc;
  }, {} as Record<any, any>);
};
export const removeUndefinedValues = (input: Record<string, any>): Record<string, any> => {
  const cleaned: Record<string, any> = {};

  for (const key in input) {
    const value = input[key];

    if (typeof value === 'object' && value !== null) {
      const cleanedValue: Record<string, any> = {};
      for (const subKey in value) {
        if ((subKey === 'valueFeet' || subKey === 'valueInches') && value[subKey] !== undefined) {
          cleanedValue[subKey] = value[subKey];
        } else if (subKey === 'valuePounds' && value[subKey] !== undefined) {
          const valuePounds = value.valuePounds;
          cleanedValue[subKey] = valuePounds;
        } else if (value[subKey] !== undefined) {
          cleanedValue[subKey] = value[subKey];
        }
      }
      if (Object.keys(cleanedValue).length > 0) {
        cleaned[key] = cleanedValue;
      }
    } else if (value !== undefined && value !== '') {
      cleaned[key] = value;
    }
  }

  return cleaned;
};

export const checkTimeframe = (
  currentInterview: InterviewFormatDto,
  submitAnswers: any,
  currentSequenceItems?: SequenceItemDto[],
  timeframeInDays = 0
): string => {
  if (currentInterview.hasTimeframe) {
    let totalTime = 0;
    currentSequenceItems?.forEach((item) => {
      const submitAnswer = submitAnswers[getQuestionControlName(item.questionId)];
      if (item.question.enableTimeframeChecking && !isNaN(submitAnswer)) {
        totalTime += Number(submitAnswer);
      }
    });
    if (totalTime > timeframeInDays) {
      return t('interview.error.sumOfDaysExceeded', {
        totalDays: timeframeInDays.toString(),
        s: timeframeInDays > 1 ? 's' : '',
      });
    }
  }
  return '';
};
